import React, { forwardRef } from 'react'
import { CheckDiv } from "./styles"

const CheckField = forwardRef((props, ref) => (
  <CheckDiv className={props.col}>
    <div className="custom-control custom-switch">
      <input className="custom-control-input" type="checkbox" id={props.name} name={props.name} ref={ref} />
      <label className="custom-control-label" htmlFor={props.name}>{props.label}</label>
    </div>
  </CheckDiv>
))

export default CheckField