import { useEffect } from 'react'
import Registro from "./Registro"
import { withNamespaces } from 'react-i18next'
import useRegistros from '../../hooks/useRegistros'
import { Link } from 'wouter'
import MyAlert from '../../common/MyAlert'

const { UserTitle, UserList, UserItem } = require("../Users/styles")

function ListRegistro({ t }) {
  const { registros, isLoading, connError, authError } = useRegistros()

  useEffect(() => {
    if (connError) {
      MyAlert({ title: t('message-connError'), icon: 'error' })
    } else if (authError) {
      MyAlert({ title: t('message-userUnauthorized'), icon: 'error' })
    }
  }, [connError, authError, t])

  return (
    <div>
      <UserTitle>{t('label-headers')}</UserTitle>
      <hr className="my-4" />
      <Link className="btn btn-secondary btn-md ml-2" to="/registros/new">{t('label-goToNew')}</Link>
      <UserList>
        {isLoading && <strong>{t('message-loading')}</strong>}
        {!isLoading &&
          registros.length > 0 ?
          registros.map(item => {
            return <UserItem key={item.id}>
              <Registro
                indNumerico={item.respuestasIndicadorNumerico}
                indicador={item.indicador.descripcion}
                ronda={item.ronda.descripcion}
                pais={item.pais.descripcion}
              />
            </UserItem>
          })
          : <strong>{t('message-notRecord')}</strong>
        }
      </UserList>
    </div>
  )
}

export default withNamespaces()(ListRegistro)