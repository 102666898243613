import React, { useRef, useEffect, useState } from 'react'
import { useForm } from "react-hook-form"
import InputField from '../../inputs/InputField'
import { withNamespaces } from 'react-i18next'
import { useLocation } from 'wouter'
import useUser from '../../hooks/useUser'
import MyAlert from '../../common/MyAlert'
import { UserTitle } from './styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ErrorMessage } from '@hookform/error-message'
import { axiosService } from '../../services/axiosService'
import { useLocalStorage } from '../../hooks/useLocalStorage'
import logo from '../Common/images/Logo_OEA_CIM_MESECVI-01M.png'
import '../../styles/Main.css'

function Login({ t, onLogin }) {
  const { register, errors, formState, watch, handleSubmit } = useForm({ mode: "all" })
  const [, setLocation] = useLocation()
  const { isLoginLoading, hasLoginError, isLogged, connError } = useUser()
  const [passwordShown, setPasswordShown] = useState(false)
  const [jwt, setJWT] = useLocalStorage('jwt', '')
  const [token, setToken] = useLocalStorage('token', '')
  const [idPais, setIdPais] = useLocalStorage('idPais', 0)
  const [loading, setLoading] = useState(false)

  const usr = useRef({})
  const pwd = useRef({})
  usr.current = watch('username', '')
  pwd.current = watch('password', '')

  const onSubmit = (data, e) => {
    e.preventDefault()
    setLoading(true)
    const username = usr.current
    const password = pwd.current
    axiosService.create('/api/auth/signin', { username, password })
      .then(jwt => {
        const { accessToken, pais } = jwt.data
        setJWT(jwt.data)
        setToken(accessToken)
        setIdPais(pais.id)
      }).catch(error => {
        setLoading(false)  
        if (error?.message === 'Network Error') {
          MyAlert({ title: t('message-connError'), icon: 'error' })
        } else if (error?.response?.status === 500) {
          MyAlert({ title: t('message-connError'), icon: 'error' })         
        } else if (error?.response?.status === 400) {
          MyAlert({ title: t(error.response.data.message), icon: 'error' })
        } else {
          MyAlert({ title: t('message-error'), icon: 'error' })
        }
      }).finally(() => {        
        return () => {    
          setLoading(false)      
          setJWT(null)
          setToken(null)
          setIdPais(null)
          setPasswordShown(null)
        }
      })
  }

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  }

  const newLine = (line) => {
    return line.split('\n').map(str => <UserTitle key={str}>{str}</UserTitle>)
  }

  useEffect(() => {
    if (connError) {
      MyAlert({ title: t('message-connError'), icon: 'error' })
    } else if (hasLoginError) {
      MyAlert({ title: t('message-invalidCredentials'), icon: 'error' })
    } else if (isLogged) {
      onLogin && onLogin() 
      setLocation("/fichaIdentificacion")
    }
  }, [isLogged, onLogin, hasLoginError, connError, t, setLocation])

  return (
    <div className="row container-projects">
      <div className="col-6">
        <img src={logo} alt="Bienvenidos" />
      </div>
      <div className="card col-6 col-md-8 col-lg-6 shadow">
        {isLoginLoading && <strong>{t('message-checkCredentials')}</strong>}
        {!isLoginLoading &&
          <div>
            {newLine(t('subtitle-cuartaRonda'))}
            <hr className="my-4" />
            <form onSubmit={handleSubmit(onSubmit)} className="needs-validation">
              <div className="row">
                <div className="col">
                  <InputField name="username" type="text" ref={register({ required: "required-field" })} label={t('label-username')} errors={errors} col="form-group col-md-12" icon="user" />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="form-group mb-2">
                    <label className={register ? 'font-weight-bold' : 'font-weight-normal'}>{t('label-password')}</label>
                    <span className="input-container">
                      <i onClick={togglePasswordVisiblity}><FontAwesomeIcon icon={['fas', passwordShown ? 'eye-slash' : 'eye']} /></i>
                      <input type={passwordShown ? "text" : "password"} className="form-control" name="password" ref={register({
                        required: "required-field",
                        minLength: {
                          value: 8,
                          message: t('message-passwordLength')
                        }
                      })}
                      />
                    </span>
                    <ErrorMessage errors={errors} name="password" render={({ message }) => <p className="error"><small>{t(message)}</small></p>} />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <button type="submit" className="btn btn-primary btn-customized col-md-12 mb-4" disabled={!formState.isValid}>
                    {loading ? t('message-loading') : t('label-login')}
                  </button>
                </div>
              </div>
            </form>
          </div>
        }
      </div>
    </div>
  )
}

export default withNamespaces()(Login)