import { useEffect, useState } from "react"
import { axiosService } from "../services/axiosService"
import useError from "./useError"

function useFields({ tabla }) {
  const { conn, auth, getError } = useError()
  const [fields, setFields] = useState([])
  const [loading, setLoading] = useState(false)
  const _url = `/api/v1/tablaDetalle/${tabla}`

  useEffect(() => {
    setLoading(true)
    axiosService.getAll(_url)
      .then(results => {
        setFields(results.data)
      }).catch(err => {
        getError({ error: err })
      }).finally(() => setLoading(false))
  }, [setFields, getError, tabla, _url])

  return {
    connError: conn,
    authError: auth,
    fields,
    isLoading: loading
  }
}

export default useFields