import { Card, CardTitle } from "../Users/styles"
import { withNamespaces } from 'react-i18next'

function Registro({ indNumerico, indicador, ronda, pais, t }) {
  return (
    <Card className="card shadow rounded bg-white">
      <div className="card-body">
        <CardTitle className="card-title">{indicador}</CardTitle>
        <hr />
        <p className="card-text">{t('label-round')}: {ronda}</p>
        <p className="card-text">{t('label-country')}: {pais}</p>
        <p className="card-text">{t('label-respuestasIndicadorNumerico')}: {indNumerico}</p>
      </div>
    </Card>
  )
}

export default withNamespaces()(Registro)