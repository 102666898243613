import { useCallback, useState } from "react"

function useError() {
  const [state, setState] = useState({ connError: false, authError: false })
  const getError = useCallback(({ error }) => {
    switch (error.message) {
      case 'Network Error':
        setState({ connError: true, authError: false })
        return
      case 'Request failed with status code 401':
        setState({ connError: false, authError: true })
        return
      default:
        break
    }
  }, [setState])
  return {
    conn: state.connError,
    auth: state.authError,
    getError
  }
}

export default useError