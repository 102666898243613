import { Component } from "react";
import Export from "./Export";

class CallExport extends Component {
  render() {
    return (
      <div className="App container">
        <Export />
      </div>
    );
  }
}

export default CallExport