import styled from '@emotion/styled'

export const UserTitle = styled.h3({
  marginBottom: "0.7rem",
  marginTop: "0.6rem",
  display: "flex",
  justifyContent: "center",
  textAlign: "center"
})

export const SubTitle = styled.h5({
  marginBottom: "0.7rem",
  marginTop: "0.6rem",
  display: "flex",
  justifyContent: "center",
  textAlign: "center"
})

export const UserList = styled.div({
  display: "flex",
  justifyContent: "center",
  flexWrap: "wrap"
})

export const UserItem = styled.div({
  width: "30%",
  margin: "1rem"
})

export const Card = styled.div({
  textAlign: "center"
})

export const CardTitle = styled.div({
  fontWeight: "bold"
})

export const BodyContent = styled.div({
  textAlign: "justify",
  marginBottom: "0.7rem",
  marginTop: "0.6rem",
  display: "flex"
})