import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ErrorMessage } from '@hookform/error-message'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { withNamespaces } from 'react-i18next'
import { Link, useRoute } from 'wouter'
import MyAlert from '../../common/MyAlert'
import useFields from '../../hooks/useFields'
import useIndicadorTabla from '../../hooks/useIndicadorTabla'
import { axiosService } from '../../services/axiosService'
import ButtonSubmit from '../Common/ButtonSubmit'
import Header from './Header'
import i18next from 'i18next'
import { generateYearsBetween } from '../Common/generateBetweenYears'
import Loader from "../Common/Loader"
const _url = `/api/v1`

function EditFicha({ t }) {
  const [match, params] = useRoute("/ficha/:tabla/edit/:pk/indicador/:ind")
  const { pk, tabla, ind } = params
  const enviar = `${tabla}/${pk}`
  const { handleSubmit, formState, register, errors, reset, onChange } = useForm({
    mode: "all"
  })
  const { fields } = useFields({ tabla: tabla })
  const { indicador } = useIndicadorTabla({ url: tabla })
  const [loading, setLoading] = useState(false)
  const [checked, setChecked] = useState(false)
  const [charge, setCharge] = useState(false)

  const onSubmit = (data, e) => {
    e.preventDefault()
    setLoading(true)
    axiosService.update(`${_url}/${enviar}`, data)
      .then(response => {
        if (response.status === 200) {
          setLoading(false)
          MyAlert({ title: t('message-successful'), icon: 'success' })
        }
      }).catch(error => {
        if (error.message === 'Request failed with status code 401') {
          MyAlert({ title: t('message-userUnauthorized'), icon: 'error' })
        } else if (error.message === 'Network Error') {
          MyAlert({ title: t('message-connError'), icon: 'error' })
        }
      }).finally(() => setLoading(false))
  }

  useEffect(() => {
    setTimeout(() => {
      setCharge(true)
      axiosService.getAll(`/api/v1/${tabla}/${pk}`)
        .then(result => reset(result.data))
        .catch(error => {
          if (error?.response?.status === 404) {
            MyAlert({ title: t('message-notRecord'), icon: 'error' })
          }
        })
        .finally(() => setCharge(false))
    }, 1000)
  }, [pk, reset, tabla, t])

  useEffect(() => {
    if (onChange)
      onChange(checked)
  }, [checked, onChange])

  const formValid = (type, nameField) => {
    return `${type} ${!(errors && errors[nameField]) && formState.touched[nameField] && 'is-valid'}`
  }

  return (
    <div>
      <Loader loading={charge} />
      {
        fields && fields.length > 0
          ?
          <form onSubmit={handleSubmit(onSubmit)} className="needs-validation">
            <Header
              indicador={indicador}
              title={t('label-indicator')}
              mode={t('label-edition')}
              code={ind}
            />
            <div className="mb-3 row">
              <input name="indicadorPorRonda[id]" type="hidden" value={ind} ref={register()} />
              {
                fields.sort((a, b) => a.fieldOrder > b.fieldOrder ? 1 : -1)
                  .map(e => {
                    if (e.type === 'text' || e.type === 'date' || e.type === 'number' || e.type === 'float')
                      return (
                        <div className={e.col} key={e.id}>
                          <label className={e.ref ? 'form-label fw-bold' : 'form-label'}>{t(e.labelTag)}</label>
                          <span className="input-container">
                            <i><FontAwesomeIcon icon={e.icon ? e.icon : 'female'} /></i>
                            <input
                              type={e.type === 'float' ? 'number' : e.type}
                              name={e.nameField}
                              className={formValid('form-control', e.nameField)}
                              placeholder={i18next.language === 'es' ? e.descripcionCampo : e.descripcionCampoEn}
                              ref={e.ref === 'required' ? register({ required: 'required-field' }) : register()}
                              step={e.type === 'float' ? '0.01' : null}
                              precision={e.type === 'float' ? '2' : null}
                              disabled={e.disabled === 'true' ? !checked : null}
                              min={e.type === 'float' || e.type === 'number' ? '0' : null}
                            />
                          </span>
                          <ErrorMessage errors={errors}
                            name={e.nameField}
                            render={({ message }) =>
                              <small className="error">
                                {t(message)}
                              </small>}
                          />
                        </div>
                      )
                    if (e.type === 'select' || e.type === 'select-list')
                      return (
                        <div className={e.col}>
                          <label className={e.ref ? 'form-label fw-bold' : 'form-label'}>{t(e.labelTag)}</label>
                          <span className="input-container">
                            <i><FontAwesomeIcon icon={e.icon ? e.icon : 'female'} /></i>
                            <select
                              name={e.type === 'select' ? `${e.nameField}[id]` : `${e.nameField}`}
                              className={formValid('form-select', e.nameField)}
                              ref={e.ref === 'required' ? register({ required: 'required-field' }) : register()}
                            >
                              <option value="">{e.descripcionCampo}</option>
                              {
                                e.type === 'select' && <Opcion url={e.url} />
                              }
                              {
                                e.type === 'select-list' && generateYearsBetween(2018).map(item => {
                                  return <option value={item} key={item}>{item}</option>
                                })
                              }
                            </select>
                          </span>
                          <ErrorMessage errors={errors}
                            name={e.type === 'select' ? `${e.nameField}[id]` : `${e.nameField}`}
                            render={({ message }) =>
                              <small className="error">
                                {t(message)}
                              </small>}
                          />
                        </div>
                      )
                    if (e.type === 'multiple')
                      return (
                        <div className={e.col}>
                          <label className={e.ref ? 'form-label fw-bold' : 'form-label'}>{t(e.labelTag)}</label>
                          <span className="input-container">
                            <i><FontAwesomeIcon icon={e.icon ? e.icon : 'female'} /></i>
                            <GetOpcion url={e.url} ref={register()} nameField={e.nameField} />
                          </span>
                          <ErrorMessage errors={errors}
                            name={e.nameField}
                            render={({ message }) =>
                              <small className="error">
                                {t(message)}
                              </small>}
                          />
                        </div>
                      )
                    if (e.type === 'boolean')
                      return (
                        <div className="form-check form-switch">
                          <input className="form-check-input" type="checkbox" id={e.nameField} name={e.nameField} ref={register()} onChange={e.disabled === 'true' ? f => { setChecked(f.target.checked) } : null} />
                          <label className="form-check-label" htmlFor={e.nameField}>{t(e.labelTag)}</label>
                        </div>
                      )
                    if (e.type === 'textarea')
                      return (
                        <div className={e.col}>
                          <label className={e.ref ? 'form-label fw-bold' : 'form-label'}>{t(e.labelTag)}</label>
                          <span className="input-container">
                            <i><FontAwesomeIcon icon={e.icon ? e.icon : 'female'} /></i>
                            <textarea
                              name={e.nameField}
                              className={formValid('form-control', e.nameField)}
                              placeholder={i18next.language === 'es' ? e.descripcionCampo : e.descripcionCampoEn}
                              ref={e.ref === 'required' ? register({ required: 'required-field' }) : register()}
                              rows={3}
                            />
                          </span>
                          <ErrorMessage errors={errors}
                            name={e.nameField}
                            render={({ message }) =>
                              <small className="error">
                                {t(message)}
                              </small>}
                          />
                        </div>
                      )
                    return null
                  })
              }
            </div>
            {
              fields && fields.length > 0 &&
              <div>
                <div className="row">
                  <div className="col-md-6 d-md-block mb-2">
                    <ButtonSubmit formState={formState} loading={loading} label={t('button-update')} />{" "}
                    <Link className="btn btn-secondary btn-lg" to={`/ficha/${tabla}/indicador/${ind}`}>{t('button-back')}</Link>
                  </div>
                </div>
              </div>
            }
          </form>
          : <div className='mb-2'>
            <small>{t('message-noField')}</small>
            <Link className="btn btn-secondary btn-lg" to='/'>{t('button-back')}</Link>
          </div>
      }
    </div>
  )
}

function Opcion({ url }) {
  const [lista1, setLista1] = useState([])

  useEffect(() => {
    axiosService.getAll(`${url}`)
      .then(res => {
        const items = res.data
        setLista1(items)
        return () => {
          setLista1([])
        }
      })
  }, [url])

  return (
    lista1.map(item => {
      return <option key={item.id} value={item.id}>{i18next.language === 'es' ? item.descripcion : item.descripcionEn}</option>
    })
  )
}

const GetOpcion = React.forwardRef(({ url, nameField }, ref) => {
  const [lista1, setLista1] = useState([])

  useEffect(() => {
    axiosService.getAll(`${url}`)
      .then(res => {
        const items = res.data
        setLista1(items)
        return () => {
          setLista1([])
        }
      })
  }, [url])

  return (
    <div style={{ height: '100px', overflowY: 'auto', width: '100%' }}>
      {
        lista1 && lista1.length > 0 && lista1.map(x => {
          return <div key={x.id} className="form-check m-2">
            <input type="checkbox" ref={ref} name={`${nameField}[]`} id={x.id} value={x.id} className="form-check-input" />
            <label htmlFor={x.id} className="form-check-label">{i18next.language === 'es' ? x.descripcion : x.descripcionEn}</label>
          </div>
        })
      }
    </div>
  )
})

export default withNamespaces()(EditFicha)