import React, { useState, createContext } from 'react'

const Context = createContext({})

export function UserContextProvider({ children }) {
  const [jwt, setJWT] = useState(() =>
    localStorage.getItem('jwt'),
    localStorage.getItem('token')
  )

  return <Context.Provider value={{ jwt, setJWT }}>
    {children}
  </Context.Provider>
}

export default Context