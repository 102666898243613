import { axiosWrapper } from '../helpers/axiosWrapper'
import api from './api'

export const axiosService = {
  getAll,
  create,
  update,
  remove,
  getWA
}

function devuelve(method, url, data) {  
  try {
    if (method === 'get')
      return api.get(url)
    if (method === 'post')
      return api.post(url, data)
    if (method === 'put')
      return api.put(url, data)
    if (method === 'remove')
      return api.delete(url)
  } catch (error) {
    if (error.message === 'Request failed with status code 401')
      localStorage.clear()
    throw new Error(error.message)
  }
}

async function getAll(url) {
  return devuelve('get', url, null)
}

async function create(url, data) {
  return devuelve('post', url, data)
}

async function update(url, data) {
  return devuelve('put', url, data)
}

async function remove(url) {
  return devuelve('remove', url, null)
}

async function getWA(url) {
  return axiosWrapper.getWA(url)
}