import { UserTitle, UserList } from "../Users/styles"
import { withNamespaces } from 'react-i18next'
import useIndicadores from "../../hooks/useIndicadores"
import Indicador from "./Indicador"
import HeadTable from "./HeadTable"
import { Link } from "wouter"
import { useState, useEffect } from "react"
//import '../../styles/styles.css'
import MyAlert from "../../common/MyAlert"

function ListIndicadores({ t }) {
  const { isLoading, indicadores, connError, authError } = useIndicadores()
  const [lista, setLista] = useState([])

  const changeSelect = e => {
    e.preventDefault()
    let y = indicadores
    y = y.filter(i => i.descripcion.toLowerCase().includes(e.target.value.toLowerCase()))
    setLista(y)
  }

  useEffect(() => {
    if (connError) {
      MyAlert({ title: t('message-connError'), icon: 'error' })
    } else if (authError) {
      MyAlert({ title: t('message-userUnauthorized'), icon: 'error' })
    } else {
      setLista(indicadores)
    }
  }, [indicadores, authError, connError, t])

  return (
    <div>
      <UserTitle>{t('label-indicators')}</UserTitle>
      <div>
        <div className="row">
          <div className="card col rounded shadow mb-4">
            <div className="row mt-2">
              <div className="form-group col-md-6 d-md-block">
                <input className="form-control" name="search" placeholder="Buscar indicador" onChange={changeSelect} />
              </div>
              <div className="form-group col-md-6 d-md-flex justify-content-md-end">
                <Link style={{ alignContent: 'right' }} className="btn btn-secondary" to="/indicadores/new">{t('label-new')}</Link>
              </div>
            </div>
            <UserList>
              {isLoading && <strong>{t('message-loading')}</strong>}
              {!isLoading &&
                lista.length > 0 ?
                <div className="table-responsive">
                  <table className="table table-hover">
                    <HeadTable
                      values={[
                        { size: '5', label: t('label-description') },
                        { size: '2', label: t('label-table') },
                        {
                          size: '4',
                          label: t('label-module').concat(' / ').concat(t('label-object')).concat(' / ').concat(t('label-level')).concat(' / ').concat(t('label-typeIndicator'))
                        },
                        { size: '1', label: t('label-ops') }
                      ]}
                    />
                    <tbody>
                      {
                        lista.map(item => {
                          const { id, descripcion, tablaFichaTecnica, modulo, objeto, nivel, tipoIndicador } = item
                          return (
                            <Indicador
                              key={id}
                              id={id}
                              descripcion={descripcion}
                              ficha={tablaFichaTecnica}
                              modulo={modulo.descripcion}
                              objeto={objeto.descripcion}
                              nivel={nivel.descripcion}
                              tipo={tipoIndicador.descripcion}
                            />
                          )
                        })
                      }
                    </tbody>
                  </table>
                </div>
                : <strong>{t('message-notRecord')}</strong>
              }
            </UserList>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withNamespaces()(ListIndicadores)