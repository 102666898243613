import { ScaleLoader } from "react-spinners"

function Loader({ loading }) {
  const override = `
        display: flex;
        align-items: center;
        justify-content: center;
        border-color: red;
    `

  return (
    <div>
      <ScaleLoader
        css={override}
        size={150}
        color={"#eb4034"}
        loading={loading}
      />
    </div>
  )
}

export default Loader