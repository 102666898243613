import { useState } from "react";
import MapChart from "./MapChart";
import ReactTooltip from "react-tooltip";
import { SubTitle, UserTitle } from "../Users/styles";
import { withNamespaces } from 'react-i18next';

function ReportesPais({ t }) {
  const [content, setContent] = useState("");

  return (
    <div>
      <UserTitle>{t('title-reporteIndicadoresPais')}</UserTitle>
      <hr />
      <SubTitle>{t('title-subIndicadoresPais')}</SubTitle>
      <MapChart setTooltipContent={setContent} />
      <ReactTooltip>{content}</ReactTooltip>
    </div>
  )
}

export default withNamespaces()(ReportesPais)