import { useEffect, useState } from "react"
import { axiosService } from "../services/axiosService"
import useError from "./useError"
const _url = `/api/v1/idiomas`

function useIdiomas() {
  const { conn, auth, getError } = useError()
  const [idiomas, setIdiomas] = useState([])

  useEffect(() => {
    axiosService.getAll(_url)
      .then(item => {
        setIdiomas(item.data)
      }).catch(err => {
        getError({ error: err })
      })
  }, [setIdiomas, getError])

  return {
    connError: conn,
    authError: auth,
    idiomas
  }
}

export default useIdiomas