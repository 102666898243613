import { useEffect, useState } from 'react'
import { withNamespaces } from 'react-i18next'
import SelectField from "../../inputs/SelectField"
import { Link } from 'wouter'
import useNiveles from '../../hooks/useNiveles'
import useIdiomas from '../../hooks/useIdiomas'
import useObjetos from '../../hooks/useObjetos'
import useTipoIndicadores from '../../hooks/useTipoIndicadores'
import useModulos from '../../hooks/useModulos'
import useIndicadores from '../../hooks/useIndicadores'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import useRondas from '../../hooks/useRondas'
import usePaises from '../../hooks/usePaises'
import CheckField from '../../inputs/CheckField'
import InputField from '../../inputs/InputField'
import RadioButtonField from '../../inputs/RadioButtonField'
import { axiosService } from '../../services/axiosService'
import useError from '../../hooks/useError'
import MyAlert from '../../common/MyAlert'
import ButtonSubmit from '../Common/ButtonSubmit'
import { UserTitle } from '../Users/styles'
const _url = `/api/v1/registroIndicadores`

function NewRegistro({ t }) {
  const methods = useForm({ mode: "all" })
  const [key, setKey] = useState('home')
  const { conn, auth, getError } = useError()
  const [loading, setLoading] = useState(false)

  const onSubmit = (data, e) => {
    e.preventDefault()
    setLoading(true)
    axiosService.create(_url, data)
      .then(res => {
        if (res.data.message === 'RegistroIndicador recorded successfully!')
          MyAlert({ title: t('message-successful'), icon: 'success' })
      }).catch(err => {
        getError({ error: err })
      })
    if (conn) {
      MyAlert({ title: t('message-connError'), icon: 'error' })
    } else if (auth) {
      MyAlert({ title: t('message-userEnauthorized'), icon: 'error' })
    }
    setLoading(false)
  }

  return (
    <div>
      <UserTitle>{t('label-newHeader')}</UserTitle>
      <hr className="my-4" />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} className="needs-validation">
          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
          >
            <Tab eventKey="home" title={t('label-criteria')}>
              <First t={t} />
            </Tab>
            <Tab eventKey="profile" title={t('label-newHeader')}>
              <Second t={t} />
            </Tab>
          </Tabs>
          <ButtonSubmit formState={methods.formState} loading={loading} label={t('button-save')} />
          <Link className="btn btn-secondary btn-lg ml-2" to='/registros'>{t('button-back')}</Link>
        </form>
      </FormProvider>
    </div>
  )
}

function First({ t }) {
  const { idiomas, authError, connError } = useIdiomas()
  const { modulos } = useModulos()
  const { niveles } = useNiveles()
  const { objetos } = useObjetos()
  const { tipos } = useTipoIndicadores()
  const { indicadores, isLoading } = useIndicadores()
  const [lista, setLista] = useState([])
  const { register } = useFormContext()

  const [state, setState] = useState({
    idioma: '',
    modulo: '',
    objeto: '',
    nivel: '',
    tipo: ''
  })

  const changeSelect = e => {
    e.preventDefault()
    setState({ ...state, [e.target.name]: e.target.value })
    let y = indicadores
    if (e.target.name === 'idioma') {
      if (e.target.value)
        y = y.filter(x => x.idioma.id === Number(e.target.value))
    } else if (state.idioma) {
      y = y.filter(x => x.idioma.id === Number(state.idioma))
    }

    if (e.target.name === 'modulo') {
      if (e.target.value)
        y = y.filter(x => x.modulo.id === Number(e.target.value))
    } else if (state.modulo) {
      y = y.filter(x => x.modulo.id === Number(state.modulo))
    }

    if (e.target.name === 'objeto') {
      if (e.target.value)
        y = y.filter(x => x.objeto.id === Number(e.target.value))
    } else if (state.objeto) {
      y = y.filter(x => x.objeto.id === Number(state.objeto))
    }

    if (e.target.name === 'nivel') {
      if (e.target.value)
        y = y.filter(x => x.nivel.id === Number(e.target.value))
    } else if (state.nivel) {
      y = y.filter(x => x.nivel.id === Number(state.nivel))
    }

    if (e.target.name === 'tipo') {
      if (e.target.value)
        y = y.filter(x => x.tipoIndicador.id === Number(e.target.value))
    } else if (state.tipo) {
      y = y.filter(x => x.tipoIndicador.id === Number(state.tipo))
    }
    setLista(y)
  }

  useEffect(() => {
    if (connError) {
      MyAlert({ title: t('message-connError'), icon: 'error' })
    } else if (authError) {
      MyAlert({ title: t('message-userUnauthorized'), icon: 'error' })
    } else {
      setLista(indicadores)
    }
  }, [connError, authError, t, indicadores])

  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-4" >
            <div className="card rounded shadow">
              <h5 className="card-header">
                <button type="button" className="btn btn-primary btn-circle btn-sm">1</button>
                &nbsp;{t('label-criteria')}
              </h5>
              <div className="card-body">
                <div className="form-row">
                  <SelectField data={idiomas} name="idioma" change={changeSelect}
                    label={t('label-language')} col="form-group col-md-12"
                    icon="globe" placeholder={t('label-selectDefaultValue')} />
                </div>
                <div className="form-row">
                  <SelectField data={modulos} name="modulo" change={changeSelect}
                    label={t('label-module')} col="form-group col-md-12"
                    icon="tram" placeholder={t('label-selectDefaultValue')}
                    size="85%" />
                </div>
                <div className="form-row">
                  <SelectField data={niveles} name="nivel" change={changeSelect}
                    label={t('label-level')} col="form-group col-md-12"
                    icon="level-up-alt" placeholder={t('label-selectDefaultValue')}
                    size="85%" />
                </div>
                <div className="form-row">
                  <SelectField data={objetos} name="objeto" change={changeSelect}
                    label={t('label-object')} col="form-group col-md-12"
                    icon="object-ungroup" placeholder={t('label-selectDefaultValue')} />
                </div>
                <div className="form-row">
                  <SelectField data={tipos} name="tipo" change={changeSelect}
                    label={t('label-typeIndicator')} col="form-group col-md-12"
                    icon="certificate" placeholder={t('label-selectDefaultValue')}
                    size="85%" />
                </div>
              </div>
            </div>
          </div>
          <div className="col-6 col-md-8" >
            <div className="card bg-light rounded shadow">
              <h5 className="card-header" style={{ position: 'relative' }}>
                <button type="button" className="btn btn-primary btn-circle btn-sm">2</button>
                &nbsp;{t('label-indicators')}
              </h5>
              <div className="card-body">
                <ul className="list-group list-group-flush">
                  {isLoading && <strong>{t('message-waiting')}</strong>}
                  {!isLoading && lista.length > 0 ?
                    lista.map((valor, index) => {
                      return <li className="list-group-item bg-light" key={valor.id}>
                        <RadioButtonField name="indicador[id]" value={valor} id={`indicador_${index}`} ref={register({ required: "required-field" })} />
                      </li>
                    })
                    : <strong>{t('message-notRecord')}</strong>
                  }
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function Second({ t }) {
  const { rondas, authError, connError } = useRondas()
  const { paises } = usePaises()
  const { register, errors } = useFormContext()

  useEffect(() => {
    if (connError) {
      MyAlert({ title: t('message-connError'), icon: 'error' })
    } else if (authError) {
      MyAlert({ title: t('message-userUnauthorized'), icon: 'error' })
    }
  }, [connError, authError, t])

  return (
    <div style={{ height: '300px' }}>
      <h5 className="card-header" style={{ position: 'relative' }}>
        <button type="button" className="btn btn-primary btn-circle btn-sm">3</button>
        &nbsp;{t('label-supplementary-data')}
      </h5>
      <div className="form-row">
        <SelectField data={rondas} name="ronda[id]"
          ref={register({ required: "required-field" })}
          label={t('label-round') + ' / ' + t('label-date-from') + ' : ' + t('label-date-to')} errors={errors} col="form-group col-md-6"
          icon="circle" placeholder={t('label-selectDefaultValue')} />
        <SelectField data={paises} name="pais[id]"
          ref={register({ required: "required-field" })}
          label={t('label-country')} errors={errors} col="form-group col-md-6"
          icon="globe" placeholder={t('label-selectDefaultValue')} />
      </div>
      <div className="form-row">
        <CheckField name="respuestasIndicadorBoolean" label={t('label-respuestasIndicadorBoolean')} col="form-group col-md-6" ref={register} />
        <InputField name="respuestasIndicadorNumerico" type="number" ref={register({ required: "required-field" })} label={t('label-respuestasIndicadorNumerico')} errors={errors} col="form-group col-md-6" icon="reply-all" />
      </div>
    </div>
  )
}

export default withNamespaces()(NewRegistro)