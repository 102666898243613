import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ErrorMessage } from "@hookform/error-message"
import ButtonSubmit from "../Common/ButtonSubmit"
import { UserTitle } from "../Users/styles"
import { withNamespaces } from "react-i18next"
import { useEffect, useState } from "react"
import MyAlert from "../../common/MyAlert"
import { axiosService } from "../../services/axiosService"
import { useForm } from "react-hook-form"
import { Link, useLocation, useRoute } from "wouter"
import useIndicadoresRonda from "../../hooks/useIndicadoresRonda"
const url = `/api/v1/fichaIdentificacion`

const Edit = ({ t }) => {
  const [match, params] = useRoute("/fichaIdentificacion/edit/:pk")
  const { pk } = params
  const { handleSubmit, formState, register, errors, reset } = useForm({
    mode: "all"
  })
  const { connError, authError } = useIndicadoresRonda()
  const [loading, setLoading] = useState(false)
  const [, setLocation] = useLocation()
  const pais = JSON.parse(localStorage.getItem('jwt')) ? JSON.parse(localStorage.getItem('jwt')).pais.id : null

  const onSubmit = (data, e) => {
    e.preventDefault()
    setLoading(true)
    axiosService.update(`${url}/${pk}`, data)
      .then(response => {
        if (response.status === 200) {
          MyAlert({ title: t('message-successful'), icon: 'success' })
        }
      }).catch(error => {
        if (error.message === 'Request failed with status code 401') {
          MyAlert({ title: t('message-userUnauthorized'), icon: 'error' })
        } else if (error.message === 'Request failed with status code 500') {
          MyAlert({ title: t('messageNoRecordCountry'), icon: 'error' })
        } else if (error.message === 'Network Error') {
          MyAlert({ title: t('message-connError'), icon: 'error' })
        }
      }).finally(() => {
        setTimeout(() => {
          setLoading(false)
        }, 3000)
      })
  }

  useEffect(() => {
    if (connError) {
      setLocation("/noconn")
      MyAlert({ title: t('message-connError'), icon: 'error' })
    } else if (authError || !JSON.parse(localStorage.getItem('jwt'))) {
      setLocation("/")
      MyAlert({ title: t('message-userUnauthorized'), icon: 'error' })
    }
  }, [t, setLocation, authError, connError, reset])

  useEffect(() => {
    axiosService.getAll(`${url}/${pk}`)
      .then(response => reset(response.data))
      .catch(error => { })
  }, [pk, reset])

  return (
    <div>
      <div className="row">
        <div className="col">
          <div>
            <UserTitle>{t('title-fichaIdentificacion')}</UserTitle>
            <hr />
          </div>
        </div>
      </div>

      <form onSubmit={handleSubmit(onSubmit)} className="needs-validation">
        <input name="pais[id]" type="hidden" value={pais} ref={register()} />
        <div className="mb-3 row">
          <div className="col">
            <label className="form-label fw-bold">{t('label-nombrePais')}</label>
            <div>{JSON.parse(localStorage.getItem('jwt')) ? JSON.parse(localStorage.getItem('jwt')).pais.descripcion : null}</div>
          </div>
          <div className="col">
            <label className="form-label fw-bold">{t('label-institucionInfo')}</label>
            <span className="input-container">
              <i><FontAwesomeIcon icon="signature" /></i>
              <input type="text" name="institucion" className="form-control" ref={register({ required: 'required-field' })} />
            </span>
            <ErrorMessage errors={errors}
              name="institucion"
              render={({ message }) =>
                <small className="error">
                  {t(message)}
                </small>}
            />
          </div>
        </div>
        <div className="mb-3 row">
          <div className="col">
            <label className="form-label fw-bold">{t('label-autoridad')}</label>
            <span className="input-container">
              <i><FontAwesomeIcon icon="signature" /></i>
              <input type="text" name="autoridad" className="form-control" ref={register({ required: 'required-field' })} />
            </span>
            <ErrorMessage errors={errors}
              name="autoridad"
              render={({ message }) =>
                <small className="error">
                  {t(message)}
                </small>}
            />
          </div>
          <div className="col">
            <label className="form-label fw-bold">{t('label-oficina')}</label>
            <span className="input-container">
              <i><FontAwesomeIcon icon="signature" /></i>
              <input type="text" name="oficina" className="form-control" ref={register({ required: 'required-field' })} />
            </span>
            <ErrorMessage errors={errors}
              name="oficina"
              render={({ message }) =>
                <small className="error">
                  {t(message)}
                </small>}
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <ButtonSubmit formState={formState} loading={loading} label={t('button-update')} />
            <Link className="btn btn-secondary btn-lg" to="/fichaIdentificacion">{t('button-back')}</Link>
          </div>
        </div>
      </form>
    </div>
  )
}

export default withNamespaces()(Edit)