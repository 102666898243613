import { useEffect, useState } from "react"
import { axiosService } from "../services/axiosService"
import useError from "./useError"
const _url = `/api/v1/paises`

function usePaises() {
  const { conn, auth, getError } = useError()
  const [paises, setPaises] = useState([])

  useEffect(() => {
    axiosService.getAll(_url)
      .then(item => {
        setPaises(item.data)
      }).catch(err => {
        getError({ error: err })
      })
  }, [setPaises, getError])

  return {
    connError: conn,
    authError: auth,
    paises
  }
}

export default usePaises