import { useEffect, useRef, useState } from "react"
import useIndicadoresRonda from "../../hooks/useIndicadoresRonda"
import useModulos from "../../hooks/useModulos"
import useObjetos from "../../hooks/useObjetos"
import i18next from 'i18next'
import { withNamespaces } from 'react-i18next'
import { Button, Modal, Tab, Table, Tabs } from "react-bootstrap"
import { Link, useLocation } from "wouter"
import MyAlert from "../../common/MyAlert"
import { UserTitle } from "../Users/styles"
import '../../styles/Borders.css'
import { axiosService } from "../../services/axiosService"
import Loader from './Loader'

function FirstAlt({ t }) {
  const { indicadores, isLoading, connError, authError } = useIndicadoresRonda()
  const [key, setKey] = useState(1)
  const [keyTwo, setKeyTwo] = useState(1)
  const { modulos } = useModulos()
  const { objetos } = useObjetos()
  const [, setLocation] = useLocation()
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const [filtro, setFiltro] = useState(null)
  const inputRef = useRef(null);
  const search = (modulo, objeto) => {
    return (
      indicadores.filter((f) => f.indicador.objeto.id === Number(objeto) && f.indicador.modulo.id === Number(modulo))
    )
  }

  useEffect(() => {
    if (connError) {
      setLocation("/noconn")
      MyAlert({ title: t('message-connError'), icon: 'error' })
    } else if (authError || !JSON.parse(localStorage.getItem('jwt'))) {
      setLocation("/")
      MyAlert({ title: t('message-userUnauthorized'), icon: 'error' })
    }
  }, [authError, connError, t, setLocation])

  const newLine = (line) => {
    return line.split('\n').map(str => <UserTitle key={str}>{str}</UserTitle>)
  }

  function handleChange(event) {
    const { value } = event.target
    if (value && value.length > 5) {
      setFiltro(indicadores.filter((f) => i18next.language === 'es' ?
      f.indicador.descripcion.toLowerCase().includes(value.toLowerCase())
      : (f.indicador.descripcionEn !== null ? f.indicador.descripcionEn.toLowerCase().includes(value.toLowerCase()) : null)
      ))
    } else {
      setFiltro(null)
    }
  }

  useEffect(() => inputRef.current && inputRef.current.focus());

  return (
    <div>
      <div className="row">
        <div className="col">
          <Loader loading={isLoading} />
        </div>
      </div>
      <div className="row">
        <div className="col">
          {newLine(t('title-cuartaRonda'))}
        </div>
      </div>
      <div className="row">
        <div className="col">
          {
            indicadores.length > 0 &&
            <Button variant="primary" onClick={handleShow} style={{ marginBottom: '1em' }}>
              {t('title-search')}
            </Button>
          }
        </div>
      </div>
      <div className="row">
        <div className="col">
          <Tabs id="controlled-tab-example" activeKey={key} onSelect={(k) => setKey(k)} variant="tabs" fill className="mb-3">
            {
              modulos.map(modulo => {
                return <Tab key={modulo.id} eventKey={modulo.orden} title={i18next.language === 'es' ? modulo.descripcion : modulo.descripcionEn}>
                  <div className="row">
                    <Tabs id="tab-2" activeKey={keyTwo} onSelect={(k) => setKeyTwo(k)} variant="tabs" className="mb-3">
                      {
                        objetos.map(objeto => {
                          return <Tab key={objeto.id} eventKey={objeto.id} title={i18next.language === 'es' ? objeto.descripcion : objeto.descripcionEn}>
                            <div className="col">
                              {
                                <Table striped responsive hover>
                                  <tbody>
                                    {
                                      search(modulo.id, objeto.id).length > 0 &&
                                      search(modulo.id, objeto.id).map((item, index) => {
                                        return <Link key={item.id} to={`/ficha/${item.indicador.tablaFichaTecnica}/indicador/${item.id}`}><tr>
                                          <td><span className="badge rounded-pill bg-primary">{index + 1}</span></td>
                                          <td className="badge bg-success">{i18next.language === 'es' ? item.indicador.nivel.descripcion : item.indicador.nivel.descripcionEn}</td>
                                          <td className="badge bg-secondary position-relative">{i18next.language === 'es' ? item.indicador.tipoIndicador.descripcion : item.indicador.tipoIndicador.descripcionEn}
                                            <span className="position-absolute top-5 start-100 translate-middle badge rounded-pill bg-danger">
                                              <CountTotal pk={item.id} tabla={item.indicador.tablaFichaTecnica} />
                                            </span>
                                          </td>
                                          <td>{i18next.language === 'es' ? item.indicador.descripcion : item.indicador.descripcionEn}</td>
                                        </tr></Link>
                                      })
                                    }
                                  </tbody>
                                </Table>
                              }
                            </div>
                          </Tab>
                        })
                      }
                    </Tabs>
                  </div>
                </Tab>
              })
            }
          </Tabs>
        </div>
      </div>
      {
        indicadores.length > 0 &&
        <Modal show={show} onHide={handleClose} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>{t('title-search')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label className="form-label">{t('label-description')}</label>
              <input type="text" name="buscar" className="form-control form-control-lg"
                onChange={handleChange} placeholder={t('message-searchIndicador')}
                ref={inputRef} />
            </div>
            {
              filtro && filtro.map(item =>
                <p key={item.id}>
                  {
                    i18next.language === 'es' ? 
                    item.indicador.descripcion
                    : item.indicador.descripcionEn
                  }
                  {" "}
                  {
                    <Link to={`/ficha/${item.indicador.tablaFichaTecnica}/indicador/${item.id}`}>
                      {
                        t('label-goToFicha') + " " + item.indicador.id
                      }
                    </Link>
                  }
                </p>)
            }
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              {t('button-back')}
            </Button>
          </Modal.Footer>
        </Modal>
      }
    </div>
  )
}

function CountTotal({ pk, tabla }) {
  const [count, setCount] = useState()

  useEffect(() => {
    if (pk !== null && tabla !== null)
      axiosService.getAll(`/api/v1/rondaIndicadores/${pk}/${tabla}/total`)
        .then(response => setCount(response.data))
        .catch(error => error)
    return () => {
      setCount(0)
    }
  }, [pk, tabla])

  return (
    <div>{count}</div>
  )
}

export default withNamespaces()(FirstAlt)