import { Route, Switch } from "wouter"
import NotFound from "../../NotFound"
import ListRegistro from "../Headers/ListRegistro"
import NewRegistro from "../Headers/NewRegistro"
import EditIndicador from "../Indicators/EditIndicador"
import ListIndicadores from "../Indicators/ListIndicadores"
import NewIndicador from "../Indicators/NewIndicador"
import EditFicha from "../Round/EditFicha"
import Ficha from "../Round/Ficha"
import UserNew from "../Users/New"
import UserEdit from "../Users/Edit"
import List from "../Users/List"
import Login from "../Users/Login"
import Tabla from "../Labels/List"
import EditLabel from "../Labels/Update"
import NewLabel from "../Labels/New"
import FirstPage from "./FirstAlt"
import New from "../Resultados/New"
import Edit from "../Resultados/Edit"
import NewFichaIdentificacion from "../Fichas/New"
import EditFichaIdentificacion from "../Fichas/Edit"
import ShowFicha from "../Fichas/Show"
import NewDefunciones from "../Fichas/NewDefunciones"
import EditDefunciones from "../Fichas/EditDefunciones"
import ShowDefunciones from "../Fichas/ShowDefunciones"
import NewDemograficos from "../Fichas/NewDemograficos"
import EditDemograficos from "../Fichas/EditDemograficos"
import ShowDemograficos from "../Fichas/ShowDemograficos"
import NewFichaNacimientos from "../Fichas/NewFichaNacimientos"
import EditFichaNacimientos from "../Fichas/EditFichaNacimientos"
import ShowNacimientos from "../Fichas/ShowNacimientos"
import CallExport from "../PDF/CallExport"
import NoConn from "../../NoConn"
import Show from "../Round/Show"
import ShowResultado from "../Resultados/Show"
import ResetPwd from "../Users/ResetPwd"
import Reportes from "../Reportes"
import Indicadores from "../Reportes/Indicadores"
import ReportesPais from "../Reportes/ReportesPais"

const Content = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col">
          <Switch>
            <Route path="/" component={Login} />
            <Route path="/firstPage" component={FirstPage} />
            <Route path="/users/new" component={UserNew} />
            <Route path="/users" component={List} />
            <Route path="/users/:id/edit" component={UserEdit} />
            <Route path="/registros/new" component={NewRegistro} />
            <Route path="/registros" component={ListRegistro} />
            <Route path="/indicadores" component={ListIndicadores} />
            <Route path="/indicadores/new" component={NewIndicador} />
            <Route path="/indicadores/edit/:pk" component={EditIndicador} />
            <Route path="/ficha/:pk/indicador/:ind" component={Ficha} />
            <Route path="/ficha/:tabla/edit/:pk/indicador/:ind" component={EditFicha} />
            <Route path="/ficha/:tabla/show/:pk/indicador/:ind" component={Show} />
            <Route path="/tabla/:nombre" component={Tabla} />
            <Route path="/tabla/:id/edit" component={EditLabel} />
            <Route path="/tabla/:nombre/new" component={NewLabel} />
            <Route path="/resultado/:ficha/ficha/:idFichaTecnica" component={New} />
            <Route path="/resultado/:tabla/edit/:pk/ficha/:idFichaTecnica" component={Edit} />
            <Route path="/resultado/:tabla/show/:pk/ficha/:idFichaTecnica" component={ShowResultado} />
            <Route path="/fichaIdentificacion" component={NewFichaIdentificacion} />
            <Route path="/fichaIdentificacion/edit/:pk" component={EditFichaIdentificacion} />
            <Route path="/fichaIdentificacion/show/:pk" component={ShowFicha} />
            <Route path="/ficha/:pk/defunciones" component={NewDefunciones} />
            <Route path="/ficha/:ficha/defunciones/edit/:pk" component={EditDefunciones} />
            <Route path="/ficha/:ficha/defunciones/show/:pk" component={ShowDefunciones} />
            <Route path="/ficha/:pk/demograficos" component={NewDemograficos} />
            <Route path="/ficha/:ficha/demograficos/edit/:pk" component={EditDemograficos} />
            <Route path="/ficha/:ficha/demograficos/show/:pk" component={ShowDemograficos} />
            <Route path="/ficha/:pk/fichaNacimientos" component={NewFichaNacimientos} />
            <Route path="/ficha/:ficha/fichaNacimientos/edit/:pk" component={EditFichaNacimientos} />
            <Route path="/ficha/:ficha/fichaNacimientos/show/:pk" component={ShowNacimientos} />
            <Route path="/callexport" component={CallExport} />
            <Route path="/updatePwd" component={ResetPwd} />
            <Route path="/reporte" component={Reportes} />
            <Route path="/reporte/:modulo/pais/:pais" component={Indicadores} />
            <Route path="/reporte/registros_pais" component={ReportesPais} />
            <Route path="/noconn" component={NoConn} />
            <Route path="/:rest*" component={NotFound} />
          </Switch>
        </div>
      </div>
    </div>
  )
}

export default Content