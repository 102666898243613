import { useState } from 'react'
import Accordion from 'react-bootstrap/Accordion'
import Toast from 'react-bootstrap/Toast'
import { withNamespaces } from 'react-i18next'
import i18next from 'i18next'

const Header = ({ t, indicador, title, mode }) => {
  const [accordionKey] = useState("0")
  const { modulo, objeto, nivel, tipoIndicador } = indicador
  return (
    <>
      <nav aria-label="breadcrumb" style={{ backgroundColor: 'white', marginTop: '1rem' }}>
        <ol className="breadcrumb" style={{ backgroundColor: 'white' }}>
          <li className="breadcrumb-item active" aria-current="page">{i18next.language === 'es' ? modulo.descripcion : modulo.descripcionEn}</li>
          <li className="breadcrumb-item active" aria-current="page">{i18next.language === 'es' ? objeto.descripcion : objeto.descripcionEn}</li>
          <li className="breadcrumb-item active" aria-current="page">{i18next.language === 'es' ? nivel.descripcion : nivel.descripcionEn}</li>
          <li className="breadcrumb-item active" aria-current="page">{i18next.language === 'es' ? tipoIndicador.descripcion : tipoIndicador.descripcionEn}</li>
        </ol>
      </nav>
      <div className="mb-3 row">
        <div className="col">
          <Accordion defaultActiveKey={accordionKey} className='card shadow'>
            <Accordion.Item eventKey={accordionKey}>
              <Accordion.Header>
                <p>{title}&nbsp;<span className="badge bg-success">{mode}</span>&nbsp;</p>
              </Accordion.Header>
              <Accordion.Body>
                <p>{i18next.language === 'es' ? indicador.descripcion : indicador.descripcionEn}</p>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
      <div className="mb-3 row">
        <div className="col-md-6">
          {
            indicador.fuentesSugeridas && <span
              aria-live="polite"
              aria-atomic="true"
              className="position-relative"
              style={{ minHeight: 'auto' }}
            >
              <Toast>
                <Toast.Header closeButton={false}>
                  <strong className="me-auto">{t('label-suggestedFonts')}</strong>
                </Toast.Header>
                <Toast.Body>{i18next.language === 'es' ? indicador.fuentesSugeridas : indicador.fuentesSugeridasEn}</Toast.Body>
              </Toast>
            </span>
          }
        </div>
        <div className="col-md-6">
          {
            indicador.notaExplicativa && <span
              aria-live="polite"
              aria-atomic="true"
              className="position-relative"
              style={{ minHeight: 'auto' }}
            >
              <Toast>
                <Toast.Header closeButton={false}>
                  <strong className="me-auto">{t('label-note')}</strong>
                </Toast.Header>
                <Toast.Body>{i18next.language === 'es' ? indicador.notaExplicativa : indicador.notaExplicativaEn}</Toast.Body>
              </Toast>
            </span>
          }
        </div>
      </div>
    </>
  )
}

export default withNamespaces()(Header)