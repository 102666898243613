import ReactToPrint from "react-to-print"
import React from 'react'
import Salida from "./Salida"
import { Trans } from 'react-i18next'
import { UserTitle } from "../Users/styles"

class Export extends React.Component {
  render() {
    return (
      <div>
        <UserTitle><Trans>{'label-oficio'}</Trans></UserTitle>
        <hr />
        <Salida ref={(response) => (this.componentRef = response)} />
        <ReactToPrint
          content={() => this.componentRef}
          trigger={() => <button className="btn btn-primary"><Trans>{'button-printPDF'}</Trans></button>}
        />
      </div>
    )
  }
}

export default Export