import { config } from '../Constants'
const _url = `${config.url.REACT_APP_DOMAIN}/api/auth/signin`

export default async function login({ username, password }) {
  return fetch(_url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ username, password })
  }).then(res => {
    return res.json()
  }).then(res => {
    return res
  }).catch(err => {
    throw new Error(err)
  })
}