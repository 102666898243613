import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "wouter";

function Indicador({ id, descripcion, ficha, modulo, objeto, nivel, tipo }) {
  return (
    <tr>
      <td className="col-5">
        {descripcion}
      </td>
      <td className="col-2">{ficha}</td>
      <td className="col-4">{modulo} / {objeto} / {nivel} / {tipo}</td>
      <td className="col-1">
        <Link to={`/indicadores/edit/${id}`}>
          <FontAwesomeIcon icon={['fas', 'edit']} size="1x" />
        </Link>
      </td>
    </tr>
  )
}

export default Indicador