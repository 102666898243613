import { useCallback, useState } from "react"
import { axiosService } from "../services/axiosService"
import loginService from '../services/login'
import { useLocalStorage } from "./useLocalStorage"

export default function useUser() {
  const [state, setState] = useState({ loading: false, error: false, connError: false })
  const [jwt, setJWT] = useLocalStorage('jwt', '')
  const [token, setToken] = useLocalStorage('token', '')
  const [idPais, setIdPais] = useLocalStorage('idPais', 0)

  const login = useCallback(({ username, password }) => {
    setState({ loading: true, error: false, connError: false })

    loginService({ username, password })
      .then(jwt => {
        if (jwt.message === 'Bad credentials') {
          setState({ loading: false, error: true, connError: false })
        } else {
          const { accessToken, pais } = jwt
          setState({ loading: false, error: false, connError: false })
          setJWT(jwt)
          setToken(accessToken)
          setIdPais(pais.id)
        }
      }).catch(err => {
        setState({ loading: false, error: false, connError: true })
      })
  }, [setJWT, setToken, setIdPais])

  const logout = useCallback(() => {
    const username = JSON.parse(localStorage.getItem('jwt')).username
    axiosService.create(`/api/auth/logout`, { username })
      .then(response => {
        localStorage.removeItem('jwt')
        localStorage.removeItem('token')
        localStorage.removeItem('idPais')
        localStorage.clear()
      }).catch(error => error)
  }, [])

  return {
    isLogged: Boolean(localStorage.getItem('jwt')),
    isLoginLoading: state.loading,
    hasLoginError: state.error,
    connError: state.connError,
    login,
    logout,
    jwt: JSON.parse(localStorage.getItem('jwt'))
  }
}