import { useEffect } from 'react'
import ListUsers from "../../hooks/ListUsers"
import User from "./User"
import { withNamespaces } from 'react-i18next'
import { useLocation } from 'wouter'
import MyAlert from '../../common/MyAlert'

const { UserTitle, UserList, UserItem } = require("./styles")

function List({ t }) {
  const { users, isLoading, connError, authError } = ListUsers()
  const [, navigate] = useLocation()

  useEffect(() => {
    if (connError) {
      navigate('/login')
      MyAlert({ title: t('message-connError'), icon: 'error' })
    } else if (authError) {
      navigate('/')
      MyAlert({ title: t('message-userUnauthorized'), type: 'error' })
    }
  }, [connError, authError, t, navigate])

  return (
    <div>
      <UserTitle>{t('title-users')}</UserTitle>
      <UserList>
        {isLoading && <strong>{t('message-loading')}</strong>}
        {
          users ?
            users.map(user => {
              return <UserItem key={user.id}>
                <User
                  firstName={user.firstName}
                  lastName={user.lastName}
                  email={user.email}
                  username={user.username}
                  pais={user.pais.descripcion}
                  pk={user.id}
                />
              </UserItem>
            })
            : <strong>{t('message-notRecord')}</strong>
        }
      </UserList>
    </div>
  )
}

export default withNamespaces()(List)