import { useCallback, useState } from "react"
import { axiosService } from "../services/axiosService"
const url = `/api/v1/indicadores`

function useIndicador() {
  const [state, setState] = useState({ loading: false, connError: false, authError: false })
  const [lista, setLista] = useState()

  const getIndicador = useCallback(({ pk }) => {
    setState({ loading: true, authError: false, connError: false })
    axiosService.getAll(`${url}/${pk}`)
      .then(item => {
        setLista(item.data)
        setState({ loading: false, authError: false, connError: false })
      }).catch(err => {
        setState({ loading: false, authError: false, connError: true })
      })
  }, [setLista])

  return {
    loading: state.loading,
    connError: state.connError,
    authError: state.authError,
    getIndicador,
    lista
  }
}

export default useIndicador