import { Link } from "wouter";
import { Card, CardTitle } from "./styles";

export default function User({ firstName, lastName, email, username, pais, pk }) {
  return (
    <Link to={`/users/${pk}/edit`}>
      <Card className="card shadow">
        <div className="card-body">
          <CardTitle className="card-title">{lastName}&nbsp;{firstName}</CardTitle>
          <p className="card-text">{email}</p>
          <p className="card-text">({username})</p>
          <hr />
          <p className="card-text">{pais}</p>
        </div>
      </Card>
    </Link>
  )
}