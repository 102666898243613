import React from 'react';
import ReactDOM from 'react-dom';
import './i18n';
import App from './App';
import './fontawesomeCheatSheet.js';
import './fonts/DINNextLTPro-Regular.ttf';
import './styles/style.scss';
import './styles/Forms.css';
import '../node_modules/bootstrap/dist/js/bootstrap.min.js';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
