import React, { useEffect, useState } from 'react'
import i18n from '../../i18n'
import { withNamespaces } from 'react-i18next'
import { Link, useLocation, useRoute } from 'wouter'
import useUser from '../../hooks/useUser'
import '../../styles/Navbar.css'
import logo_es from './images/Logo_OEA_CIM_MESECVI-07_modificado.png'
import logo_en from './images/Logo_OEA_CIM_MESECVI-08_modificado.png'
import Swal from 'sweetalert2'
import useIndicadoresRonda from '../../hooks/useIndicadoresRonda'
import MyAlert from '../../common/MyAlert'
import { axiosService } from '../../services/axiosService'
import { saveAs } from 'file-saver'
import axios from 'axios'
import planillas_indicadores from '../../files/planillas_indicadores_junio_2022_ultimo.xlsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
const _url = `/api/v1/rondaIndicadores`

function Navbar({t}) {
  const { connError, authError } = useIndicadoresRonda()
  const { isLogged, logout } = useUser()
  const [match] = useRoute("/")
  const [, setLocation] = useLocation()
  const [confirmed, setConfirmed] = useState(null)

  const exportIssues = async () => {
    const response = await axios.get(planillas_indicadores, {
      responseType: 'arraybuffer',
    })
    const blob = new Blob([response.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })
    saveAs(blob, 'archive.xlsx')
    /*Swal.fire({
        title: 'Mensaje del Sistema',
        text: 'Estamos trabajando en la actualización del instrumento de evaluación. Proximamente disponible'
    })*/
  }

  useEffect(() => {
    if (connError) {
      setLocation("/noconn")
      MyAlert({ title: t('message-connError'), icon: 'error' })
    } else if (authError) {
      setLocation("/")
      MyAlert({ title: t('message-userUnauthorized'), icon: 'error' })
    }
  }, [connError, authError, setLocation, t])

  const loadDocument = () => {
    Swal.fire({
      title: t('title-questionTwo'),
      text: t('label-questionTwo'),
      icon: 'question',
      showDenyButton: true,
      confirmButtonColor: '#3085d6',
      denyButtonColor: '#d33',
      confirmButtonText: t('message-yes'),
      denyButtonText: t('message-no'),
    }).then(result => {
      setConfirmed(false)
      if (result.isConfirmed) {
        setConfirmed(true)
      }
    })
  }

  const signOut = () => {
    logout()
    setTimeout(() => {
      setLocation("/")
    }, 3000)
  }

  useEffect(() => {
    if (confirmed) {
      axiosService.remove(`${_url}/${JSON.parse(localStorage.getItem('idPais'))}`)
        .then(response => setLocation("/callexport"))
    }
  }, [confirmed, setLocation])

  const renderLoginButtons = ({ isLogged }) => {
    return isLogged
      ? <>
        {
          JSON.parse(localStorage.getItem('jwt')) && JSON.parse(localStorage.getItem('jwt')).roles[0] === 'ROLE_ADMIN' &&
          <><li className="nav-item">
            <Link className="dropdown-item" to='/first'>
              {t('title-user')}
            </Link>
          </li>
            <li className="nav-item">
              <Link className="dropdown-item" to='/users'>
                {t('title-users')}
              </Link>
            </li></>
        }
        {
          JSON.parse(localStorage.getItem('jwt')) && JSON.parse(localStorage.getItem('jwt')).roles[0] === 'ROLE_MODERATOR' && <>
            <li className="nav-item">
              <button className="dropdown-item" onClick={() => loadDocument()} >{t('label-questionOne')}</button>
            </li>
          </>
        }
        <li className="nav-item">
          <Link className="dropdown-item" to="/updatePwd">
            {t('label-changePwd')}
          </Link>
        </li>
        <li className="dropdown-divider"></li>
        <li className="nav-item">
          <button className="dropdown-item" onClick={() => signOut()}>{t('label-logout')}</button>
        </li></>
      : null
  }

  const content = match
    ? null
    : renderLoginButtons({ isLogged })

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng)
  }

  const menuLanguages = () => {
    return <li className="nav-item dropdown">
      <button className="nav-link dropdown-toggle" id="navbarDarkDropdownMenuLink" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        {t('menu-languages')}
      </button>
      <ul className="dropdown-menu" aria-labelledby="navbarDarkDropdownMenuLink">
        <li className="nav-item">
          <button className="dropdown-item" onClick={() => changeLanguage('es')}>{t('label-spanish')}</button>
        </li>
        <li className="nav-item">
          <button className="dropdown-item" onClick={() => changeLanguage('en')}>{t('label-english')}</button>
        </li>
      </ul>
    </li>
  }

  const renderOptions = () => {
    return isLogged
      ? <ul className="navbar-nav">
          <li className="nav-item">
            <button className="nav-link dropdown" onClick={() => setLocation("/fichaIdentificacion")}>
              {t('label-home')}
            </button>
          </li>
          <li className="nav-item">
            <button className="nav-link dropdown" onClick={() => setLocation("/firstPage")}>
              {t('label-indicators')}
            </button>
          </li>
          {
            JSON.parse(localStorage.getItem('jwt')) && JSON.parse(localStorage.getItem('jwt')).roles[0] !== 'ROLE_USER' &&
            <li className="nav-item dropdown">
              <button className="nav-link dropdown-toggle" id="navbarDarkDropdownReportesLink" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {t('label-reporte')}
              </button>
              <ul className="dropdown-menu" aria-labelledby="navbarDarkDropdownReportesLink">
                {
                  JSON.parse(localStorage.getItem('jwt')) && JSON.parse(localStorage.getItem('jwt')).roles[0] !== 'ROLE_USER' &&
                  <li className="nav-item">
                    <button className="dropdown-item" onClick={() => setLocation("/reporte")}>
                      {t('label-reporteIndicadores')}
                    </button>
                  </li>
                }
                {
                  JSON.parse(localStorage.getItem('jwt')) && JSON.parse(localStorage.getItem('jwt')).roles[0] === 'ROLE_ADMIN' &&
                  <li className="nav-item">
                    <button className="dropdown-item" onClick={() => setLocation("/reporte/registros_pais")}>
                      {t('label-reporteIndicadoresPais')}&nbsp;<span className="badge bg-info">{t('label-new')}</span>
                    </button>
                  </li>
                }
              </ul>
            </li>
          }
          <li className="nav-item">
            <button className='nav-link dropdown' onClick={() => exportIssues()}>
              {t('title-instrumento')}&nbsp;<i><FontAwesomeIcon icon='file-excel' /></i>
            </button>
          </li>
          {
            menuLanguages()
          }
          <li className="nav-item">
            <button className="nav-link disabled" tabIndex="-1" aria-disabled="true">
              {
                JSON.parse(localStorage.getItem('jwt')) ? JSON.parse(localStorage.getItem('jwt')).pais.descripcion : null
              }
            </button>            
          </li>
        </ul> : <ul className="navbar-nav">
          {
            menuLanguages()
          }
      </ul>
  }

  return (
    <nav className="navbar navbar-expand-lg navbar-dark sticky-top" >
      <div className="container-fluid">
        <Link className="nav-link" to='/'>
          {Logo({t})}
        </Link>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" 
        data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" 
        aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>        
        <div className="collapse navbar-collapse" id="navbarNavDropdown">
          {renderOptions()}
          
          <ul className="navbar-nav ms-auto">
            {
              match ?
              null :
              JSON.parse(localStorage.getItem('jwt')) &&
              <li className="nav-item dropdown">
                <button className="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                  {JSON.parse(localStorage.getItem('jwt')).firstName + " " + JSON.parse(localStorage.getItem('jwt')).lastName}
                </button>
                <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdownMenuLink">
                  {content}
                </ul>
              </li>
            }
          </ul>
        </div>
      </div>
    </nav>
  )
}

function Logo({t}) {
  return t('logo') === "logo_es" ? <img src={logo_es} alt="Bienvenidos" /> : <img src={logo_en} alt="Bienvenidos" />
}

export default withNamespaces()(Navbar)