import { useEffect, useState } from 'react'
import { useForm } from "react-hook-form"
import { withNamespaces } from 'react-i18next'
import { Link, useRoute } from 'wouter'
import MyAlert from '../../common/MyAlert'
import useIdiomas from '../../hooks/useIdiomas'
import useIndicador from '../../hooks/useIndicador'
import useModulos from '../../hooks/useModulos'
import useNiveles from '../../hooks/useNiveles'
import useObjetos from '../../hooks/useObjetos'
import useTipoIndicadores from '../../hooks/useTipoIndicadores'
import InputField from '../../inputs/InputField'
import SelectField from '../../inputs/SelectField'
import TextAreaField from '../../inputs/TextAreaField'
import { axiosService } from '../../services/axiosService'
import ButtonSubmit from '../Common/ButtonSubmit'
import { UserTitle } from '../Users/styles'
const url = `/api/v1/indicadores`

function EditIndicador({ t }) {
  const [match, params] = useRoute("/indicadores/edit/:pk")
  const { getIndicador, lista } = useIndicador()

  const { handleSubmit, formState, register, errors, reset } = useForm({
    defaultValues: {
      id: '',
      idioma: '',
      modulo: '',
      nivel: '',
      objeto: '',
      tipoIndicador: '',
      tablaFichaTecnica: '',
      descripcion: '',
      notaExplicativa: '',
      fuentesSugeridas: ''
    },
    mode: "all"
  })
  const { idiomas, authError, connError } = useIdiomas()
  const { modulos } = useModulos()
  const { niveles } = useNiveles()
  const { objetos } = useObjetos()
  const { tipos } = useTipoIndicadores()
  const [loading, setLoading] = useState(false)

  const onSubmit = (data, e) => {
    e.preventDefault()
    setLoading(true)
    axiosService.update(`${url}/${params.pk}`, data)
      .then(res => {
        if (res.status === 200)
          MyAlert({ title: t('message-successful'), icon: 'success' })
        else
          MyAlert({ title: t('message-error'), icon: 'error' })
      }).catch(error => {
        MyAlert({ title: t('message-error'), icon: 'error' })
      })
    setLoading(false)
  }

  useEffect(() => {
    if (!lista)
      getIndicador({ pk: params.pk })
    setTimeout(() => {
      reset(lista)
    }, 1000)
  }, [getIndicador, lista, params.pk, reset])

  useEffect(() => {
    if (connError) {
      MyAlert({ title: t('message-connError'), icon: 'error' })
    } else if (authError) {
      MyAlert({ title: t('message-userUnauthorized'), icon: 'error' })
    }
  }, [connError, authError, t])

  return (
    <div>
      <UserTitle>{t('label-editEndicator')}</UserTitle>
      <hr className="my-4" />
      <form onSubmit={handleSubmit(onSubmit)} className="needs-validation">
        <div className="mb-3 row">
          <SelectField data={idiomas} name="idioma[id]"
            ref={register({ required: "required-field" })}
            label={t('label-language')} errors={errors} col="form-group col-md-6"
            icon="globe" placeholder={t('label-selectDefaultValue')} />
          <SelectField data={modulos} name="modulo[id]"
            ref={register({ required: "required-field" })}
            label={t('label-module')} errors={errors} col="form-group col-md-6"
            icon="tram" placeholder={t('label-selectDefaultValue')} />
        </div>
        <div className="mb-3 row">
          <SelectField data={niveles} name="nivel[id]"
            ref={register({ required: "required-field" })}
            label={t('label-level')} errors={errors} col="form-group col-md-6"
            icon="level-up-alt" placeholder={t('label-selectDefaultValue')} />
          <SelectField data={objetos} name="objeto[id]"
            ref={register({ required: "required-field" })}
            label={t('label-object')} errors={errors} col="form-group col-md-6"
            icon="object-ungroup" placeholder={t('label-selectDefaultValue')} />
        </div>
        <div className="mb-3 row">
          <SelectField data={tipos} name="tipoIndicador[id]"
            ref={register({ required: "required-field" })}
            label={t('label-typeIndicator')} errors={errors} col="form-group col-md-6"
            icon="certificate" placeholder={t('label-selectDefaultValue')} />
          <InputField name="tablaFichaTecnica" type="text" ref={register}
            label={t('label-table')} errors={errors} col="form-group col-md-6" icon="table" />
        </div>
        <div className="mb-3 row">
          <TextAreaField name="descripcion" ref={register({ required: "required-field" })}
            errors={errors} icon="keyboard" label={t('label-description')}
            col="form-group col-md-12" />
        </div>
        <div className="mb-3 row">
          <InputField name="notaExplicativa" type="text" ref={register}
            label={t('label-note')} errors={errors} col="form-group col-md-6" icon="sticky-note" />
          <InputField name="fuentesSugeridas" type="text" ref={register}
            label={t('label-suggestedFonts')} errors={errors} col="form-group col-md-6" icon="font" />
        </div>
        <div className="mb-3 row">
          <InputField name="notaExplicativaEn" type="text" ref={register}
            label={t('label-note').concat(' / ').concat(t('label-english'))} errors={errors} col="form-group col-md-6" icon="sticky-note" />
          <InputField name="fuentesSugeridasEn" type="text" ref={register}
            label={t('label-suggestedFonts').concat(' / ').concat(t('label-english'))} errors={errors} col="form-group col-md-6" icon="font" />
        </div>
        <div className="mb-3 row">
          <TextAreaField name="descripcionEn" ref={register({ required: "required-field" })}
            errors={errors} icon="keyboard" label={t('label-description').concat(' / ').concat(t('label-english'))} col="form-group col-md-12" />
        </div>
        <div className="mb-3 row">
          <InputField name="orden" type="number" ref={register}
            label={t('label-order')} errors={errors} col="form-group col-md-6" icon="list" />
        </div>
        <div className='mb-2'>
          <ButtonSubmit formState={formState} loading={loading} label={t('button-update')} />
          <Link className="btn btn-secondary btn-lg ml-2" to='/indicadores'>{t('button-back')}</Link>
        </div>
      </form>
    </div>
  )
}

export default withNamespaces()(EditIndicador)