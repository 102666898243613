import Navbar from './components/Common/Navbar'
import { UserListContextProvider } from './context/UserListContext'
import 'react-notifications-component/dist/theme.css'
import ProgressBar from "react-scroll-progress-bar"
import Content from './components/Common/Content'
import { UserContextProvider } from './context/UserContext'

function App() {
  return (
    <UserContextProvider>
      <UserListContextProvider>
        <ProgressBar />
        <Navbar />
        <Content />
      </UserListContextProvider>
    </UserContextProvider>
  )
}

export default App