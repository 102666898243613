import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { UserTitle } from "../Users/styles"
import { withNamespaces } from "react-i18next"
import { useEffect } from "react"
import MyAlert from "../../common/MyAlert"
import { axiosService } from "../../services/axiosService"
import { useForm } from "react-hook-form"
import { Link, useLocation, useRoute } from "wouter"
import useIndicadoresRonda from "../../hooks/useIndicadoresRonda"
const url = `/api/v1/fichaIdentificacion`

const Show = ({ t }) => {
  const [match, params] = useRoute("/fichaIdentificacion/show/:pk")
  const { pk } = params
  const { register, reset } = useForm({
    mode: "all"
  })
  const { connError, authError } = useIndicadoresRonda()
  const [, setLocation] = useLocation()
  const pais = JSON.parse(localStorage.getItem('jwt')) ? JSON.parse(localStorage.getItem('jwt')).pais.id : null

  useEffect(() => {
    if (connError) {
      setLocation("/noconn")
      MyAlert({ title: t('message-connError'), icon: 'error' })
    } else if (authError || !JSON.parse(localStorage.getItem('jwt'))) {
      setLocation("/")
      MyAlert({ title: t('message-userUnauthorized'), icon: 'error' })
    }
  }, [t, setLocation, authError, connError, reset])

  useEffect(() => {
    axiosService.getAll(`${url}/${pk}`)
      .then(response => reset(response.data))
      .catch(error => { })
  }, [pk, reset])

  return (
    <div>
      <div className="row">
        <div className="col">
          <div>
            <UserTitle>{t('title-fichaIdentificacion')}</UserTitle>
            <hr />
          </div>
        </div>
      </div>

      <input name="pais[id]" type="hidden" value={pais} ref={register()} />
      <div className="mb-3 row">
        <div className="col">
          <label className="form-label fw-bold">{t('label-nombrePais')}</label>
          <div>{JSON.parse(localStorage.getItem('jwt')) ? JSON.parse(localStorage.getItem('jwt')).pais.descripcion : null}</div>
        </div>
        <div className="col">
          <label className="form-label fw-bold">{t('label-institucionInfo')}</label>
          <span className="input-container">
            <i><FontAwesomeIcon icon="signature" /></i>
            <input disabled='true' type="text" name="institucion" className="form-control" ref={register({ required: 'required-field' })} />
          </span>

        </div>
      </div>
      <div className="mb-3 row">
        <div className="col">
          <label className="form-label fw-bold">{t('label-autoridad')}</label>
          <span className="input-container">
            <i><FontAwesomeIcon icon="signature" /></i>
            <input disabled='true' type="text" name="autoridad" className="form-control" ref={register({ required: 'required-field' })} />
          </span>

        </div>
        <div className="col">
          <label className="form-label fw-bold">{t('label-oficina')}</label>
          <span className="input-container">
            <i><FontAwesomeIcon icon="signature" /></i>
            <input disabled='true' type="text" name="oficina" className="form-control" ref={register({ required: 'required-field' })} />
          </span>

        </div>
      </div>
      <div className="row">
        <div className="col">
          <Link className="btn btn-secondary btn-lg" to="/fichaIdentificacion">{t('button-back')}</Link>
        </div>
      </div>
    </div>
  )
}

export default withNamespaces()(Show)