import { useState } from "react"
import { useForm } from "react-hook-form"
import { Link, useRoute } from "wouter"
import MyAlert from "../../common/MyAlert"
import { axiosService } from "../../services/axiosService"
import ButtonSubmit from "../Common/ButtonSubmit"
import { UserTitle } from "../Users/styles"
const _url = `/api/v1/tablaDetalle`

function New() {
  const [match, params] = useRoute("/tabla/:nombre/new")
  const { nombre } = params
  const { handleSubmit, formState, register } = useForm({
    mode: "all"
  })
  const [loading, setLoading] = useState(false)

  const onSubmit = (data, e) => {
    e.preventDefault()
    setLoading(true)
    axiosService.create(`${_url}/${nombre}`, data)
      .then(response => {
        if (response.status === 201)
          MyAlert({ title: "¡Registro creado!", icon: 'success' })
        else
          MyAlert({ title: "Error al ingresar", icon: 'error' })
      })
    setLoading(false)
  }

  return (
    <div>
      <div className="row">
        <div className="col">
          <UserTitle>Crear Label</UserTitle>
          <form onSubmit={handleSubmit(onSubmit)} className="needs-validation">
            <div className="mb-3 row">
              <div className="form-group col-md-6">
                <label>Descripción Label</label>
                <input type="text" name="descripcionCampo" className="form-control" ref={register()} />
              </div>
              <div className="form-group col-md-6">
                <label>Descripción Label Inglés</label>
                <input type="text" name="descripcionCampoEn" className="form-control" ref={register()} />
              </div>
            </div>
            <div className="mb-3 row">
              <div className="form-group col-md-6">
                <label>Nombre Campo BD</label>
                <input type="text" name="nombreCampo" className="form-control" ref={register()} />
              </div>
              <div className="form-group col-md-6">
                <label>Nombre Campo Back</label>
                <input type="text" name="nameField" className="form-control" ref={register()} />
              </div>
            </div>
            <div className="mb-3 row">
              <div className="form-group col-md-6">
                <label>Tipo Campo</label>
                <input type="text" name="type" className="form-control" ref={register()} />
              </div>
              <div className="form-group col-md-6">
                <label>Validación</label>
                <input type="text" name="ref" className="form-control" ref={register()} />
              </div>
            </div>
            <div className="mb-3 row">
              <div className="form-group col-md-6">
                <label>Tamaño Campo</label>
                <input type="text" name="col" className="form-control" ref={register()} />
              </div>
              <div className="form-group col-md-6">
                <label>Ícono</label>
                <input type="text" name="icon" className="form-control" ref={register()} />
              </div>
            </div>
            <div className="mb-3 row">
              <div className="form-group col-md-6">
                <label>URL</label>
                <input type="text" name="url" className="form-control" ref={register()} />
              </div>
              <div className="form-group col-md-6">
                <label>Etiqueta Traducción</label>
                <input type="text" name="labelTag" className="form-control" ref={register()} />
              </div>
            </div>
            <div className="mb-3 row">
              <div className="form-group col-md-6">
                <label>Orden del Campo</label>
                <input type="text" name="fieldOrder" className="form-control" ref={register()} />
              </div>
              <div className="form-group col-md-6">
                <div className="form-check form-switch">
                  <input className="form-check-input" type="checkbox" id="list" name="list" ref={register()} />
                  <label className="form-check-label" htmlFor="list">¿Listar?</label>
                </div>
              </div>
            </div>
            <div className="mb-3 row">
              <div className="form-group col-md-6">
                <label>¿Habilitado? (true/false string)</label>
                <input type="text" name="disabled" className="form-control" ref={register()} />
              </div>

            </div>
            <ButtonSubmit formState={formState} loading={loading} label="Guardar" />
            <Link className="btn btn-secondary btn-lg ml-2" to={`/tabla/${nombre}`}>Regresar</Link>
          </form>
        </div>
      </div>
    </div>
  )
}

export default New