import { useCallback, useState } from "react"
import { axiosService } from "../services/axiosService"
import useError from "./useError"
const _url = `/api/v1`

function useLista() {
  const { conn, auth, getError } = useError()
  const [lista, setLista] = useState([])

  const valores = useCallback(({ url }) => {
    axiosService.getAll(`${_url}${url}`)
      .then(item => {
        setLista(item.data)
      }).catch(err => {
        getError({ error: err })
      })
  }, [setLista, getError])

  return {
    connError: conn,
    authError: auth,
    lista,
    valores
  }
}

export default useLista