import Swal from 'sweetalert2'

function MyAlert({ title, icon }) {
  return (
    Swal.fire({
      toast: true,
      position: 'bottom-end',
      showConfirmButton: false,
      timer: 3000,
      title: title,
      icon: icon
    })
  )
}

export default MyAlert