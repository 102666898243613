import { useCallback, useState } from "react"
import { axiosService } from "../services/axiosService"

function useFicha() {
  const [state, setState] = useState({ loading: false, connError: false, authError: false })
  const [ficha, setFicha] = useState()

  const getFicha = useCallback(({ url }) => {
    setState({ loading: true, authError: false, connError: false })
    axiosService.getAll(url)
      .then(response => {
        setFicha(response.data)
        setState({ loading: false, authError: false, connError: false })
      }).catch(err => {
        setState({ loading: false, authError: false, connError: true })
      })
  }, [setFicha])

  return {
    loading: state.loading,
    connError: state.connError,
    authError: state.authError,
    getFicha,
    ficha
  }
}

export default useFicha