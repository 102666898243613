import React, { useRef, useEffect, useState } from 'react'
import { useForm } from "react-hook-form"
import InputField from "../../inputs/InputField"
import { withNamespaces } from 'react-i18next'
import { useRoute } from 'wouter'
import SelectField from '../../inputs/SelectField'
import usePaises from "../../hooks/usePaises"
import ButtonSubmit from '../Common/ButtonSubmit'
import MyAlert from '../../common/MyAlert'
import { UserTitle } from './styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ErrorMessage } from '@hookform/error-message'
import { axiosService } from '../../services/axiosService'
const url = `/api/v1/users`

function Edit({ t }) {
  const [match, params] = useRoute("/users/:pk/edit")
  const { pk } = params
  const { register, errors, handleSubmit, formState, watch, reset } = useForm({ mode: "all" })
  const password = useRef({})
  password.current = watch("password", "")
  const { paises, authError, connError } = usePaises()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    axiosService.getAll(`${url}/${pk}`)
      .then(response => {
        setTimeout(() => {
          reset(response.data)
        }, 3000)
      }).catch(error => { })
  }, [pk, reset])

  useEffect(() => {
    if (connError) {
      MyAlert({ title: t('message-connError'), icon: 'error' })
    } else if (authError) {
      MyAlert({ title: t('message-invalidCredentials'), icon: 'error' })
    }
  }, [authError, connError, t])

  const onSubmit = (data, e) => {
    e.preventDefault()
    setLoading(true)
    axiosService.update(`${url}/${pk}`, data)
      .then(response => {
        if (response.status === 200) {
          setTimeout(() => {
            setLoading(false)
            MyAlert({ title: t('message-successful'), icon: 'success' })
          }, 3000)
        }
      }).catch(error => {
        if (error.message === 'Request failed with status code 401') {
          MyAlert({ title: t('message-userUnauthorized'), icon: 'error' })
        } else if (error.message === 'Request failed with status code 500') {
          MyAlert({ title: t('message-noRecordCountry'), icon: 'error' })
        } else if (error.message === 'Network Error') {
          MyAlert({ title: t('message-connError'), icon: 'error' })
        }
      }).finally(() => setLoading(false))
  }

  return (
    <div>
      <UserTitle>{t('title-editUser')}</UserTitle>
      <hr className="my-4" />
      <form onSubmit={handleSubmit(onSubmit)} className="needs-validation">
        <div className="mb-3 row">
          <InputField name="firstName" type="text" ref={register({ required: "required-field" })} label={t('label-firstName')} errors={errors} col="form-group col-md-6" icon="signature" />
          <InputField name="lastName" type="text" ref={register({ required: "required-field" })} label={t('label-lastName')} errors={errors} col="form-group col-md-6" icon="signature" />
        </div>
        <div className="mb-3 row">
          <InputField name="username" type="text" ref={register({ required: "required-field" })} label={t('label-username')} errors={errors} col="form-group col-md-4" icon="user" />
          <InputField name="idCard" type="text" ref={register()} label={t('label-idCard')} errors={errors} col="form-group col-md-4" icon="id-card" />
          <InputField name="email" type="email" label={t('label-email')} errors={errors} col="form-group col-md-4" icon="envelope"
            ref={register({
              required: "required-field",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "message-invalidEmail"
              }
            })} />
        </div>
        <div className="mb-3 row">
          <SelectField data={paises} name="pais[id]" ref={register({ required: "required-field" })}
            label={t('label-country')} errors={errors} col="form-group col-md-4"
            icon="globe" placeholder={t('label-selectDefaultValue')} />
          <InputField name="phone" type="tel" label={t('label-phone')} errors={errors} col="form-group col-md-4" icon="phone"
            ref={register({
              pattern: {
                value: /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
                message: "message-invalidPhone"
              }
            })} placeholder="1111-111-1111" />
          <InputField name="phone2" type="text" label={t('label-phone2')} errors={errors} col="form-group col-md-4" icon="phone-volume"
            ref={register({
              pattern: {
                value: /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
                message: "message-invalidPhone"
              }
            })} placeholder="1111-111-1111" />
        </div>
        <div className="mb-3 row">
          <div className="form-group col-md-6">
            <label className='font-weight-normal'>{t('label-password')}</label>
            <span className="input-container">
              <i><FontAwesomeIcon icon={['fas', 'key']} /></i>
              <input type="password" className="form-control" name="password"
                ref={register({
                  required: "required-field",
                  minLength: {
                    value: 8,
                    message: t('message-passwordLength')
                  },
                  pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,15}$/
                })} />
            </span>
            <ErrorMessage errors={errors} name="password" render={({ message }) => <p className="error"><small>{t(message)}</small></p>} />
          </div>
          <InputField name="password2" type="password" label={t('label-password2')} errors={errors} col="form-group col-md-6" icon="key"
            ref={register({
              required: "required-field",
              validate: value => value === password.current || t('message-passwordMatch')
            })} />
        </div>
        <div className="mb-3 row">
          <div className="form-group col-md-6">
            <small>{t('message-pwdConditions')}</small>
          </div>
        </div>
        <ButtonSubmit formState={formState} loading={loading} label={t('button-update')} />
      </form>
    </div>
  )
}

export default withNamespaces()(Edit)