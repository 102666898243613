import { useEffect, useState } from "react"
import { axiosService } from "../services/axiosService"
const url = `/api/v1/indicadores`

function useIndicadores() {
  const [state, setState] = useState({ loading: false, connError: false, authError: false })
  const [indicadores, setIndicadores] = useState([])

  useEffect(() => {
    setState({ loading: true, connError: false, authError: false })
    axiosService.getAll(url)
      .then(item => {
        setIndicadores(item.data)
        setState({ loading: false, connError: false, authError: false })
      }).catch(err => {
        switch (err.message) {
          case 'TypeError: Failed to fetch':
            setState({ loading: false, connError: true, authError: false })
            return
          case '401':
            setState({ loading: false, connError: false, authError: true })
            return
          default:
            break
        }
      })
  }, [setIndicadores])

  return {
    isLoading: state.loading,
    connError: state.connError,
    authError: state.authError,
    indicadores
  }
}

export default useIndicadores