import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { UserTitle } from './components/Users/styles'

const NoConn = () => (
  <div>
    <div className="row">
      <div className="col">
        <UserTitle>No connection</UserTitle>
        <div style={{ fontSize: '150px', textAlign: 'center' }}><FontAwesomeIcon icon="coffee" size="xs" /></div>
      </div>
    </div>
  </div>
)

export default NoConn