import React from 'react'
import { axiosService } from '../../services/axiosService'
import logo from '../Common/images/Logo_OEA_CIM_MESECVI-05.png'
const url = `/api/v1/fichaIdentificacion/pais`

class Salida extends React.Component {
  state = {
    ficha: []
  }

  componentDidMount() {
    axiosService.getAll(`${url}/${JSON.parse(localStorage.getItem('jwt')).pais.id}`)
      .then(response => {
        const ficha = response.data[0]
        this.setState({ ficha })
      })
  }

  render() {
    return (
      <div style={{ margin: '50px' }}>
        <div>
          <img src={logo} alt="Imagen" />
        </div>
        <p style={{ textAlign: 'right' }}>{new Date().toLocaleDateString()}</p>
        <p style={{ textAlign: 'left' }}>{this.state.ficha.institucion}</p>
        <p style={{ textAlign: 'left' }}>{this.state.ficha.autoridad}</p>
        <p style={{ textAlign: 'left' }}>{this.state.ficha.oficina}</p>
        <p style={{ textAlign: 'left', marginBottom: '2rem' }}>{JSON.parse(localStorage.getItem('jwt')).pais.descripcion}</p>
        <p style={{ textAlign: 'justify', marginBottom: '2rem' }}>En nombre de la Comisión Interamericana de Mujeres (CIM) en su
          calidad de Secretaría Técnica del Mecanismo de Seguimiento de la Convención de Belém do Pará (MESECVI), de la Organización
          de los Estados Americanos (OEA), agradecemos su participación en la Cuarta Ronda de Evaluación Multilateral del MESECVI.</p>
        <p style={{ textAlign: 'justify', marginBottom: '5rem' }}>La información suministrada ha sido registrada con éxito y se
          certifica a través de la presente comunicación la participación de su Estado en la referida Ronda de Evaluación. Agradeciendo
          su colaboración, reciban un cordial saludo,</p>
        <p style={{ textAlign: 'left' }}>Luz Patricia Mejía Guerrero</p>
        <p style={{ textAlign: 'left' }}>Secretaria Técnica del MESECVI</p>
      </div>
    )
  }
}

export default Salida