import React, { forwardRef } from 'react'
import { ErrorMessage } from '@hookform/error-message'
import '../styles/Nav.css'
import { Trans } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const InputField = forwardRef((props, ref) => (
  <div className={props.col}>
    <label className={ref ? 'font-weight-bold' : 'font-weight-normal'}>{props.label}</label>
    <span className="input-container">
      <i><FontAwesomeIcon icon={['fas', props.icon ? props.icon : 'coffee']} /></i>
      <input type={props.type} className="form-control" name={props.name} ref={ref} placeholder={props.placeholder} />
    </span>
    <ErrorMessage errors={props.errors} name={props.name} render={({ message }) => <p className="error"><small><Trans>{message}</Trans></small></p>} />
  </div>
))

export default InputField