import { useForm } from "react-hook-form"
import useFields from "../../hooks/useFields"
import ButtonSubmit from "../Common/ButtonSubmit"
import { withNamespaces } from 'react-i18next'
import React, { useEffect, useState } from "react"
import { axiosService } from "../../services/axiosService"
import MyAlert from "../../common/MyAlert"
import { ErrorMessage } from "@hookform/error-message"
import { Link, useRoute } from "wouter"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useLista from "../../hooks/useLista"
import i18next from 'i18next'
import Swal from 'sweetalert2'
import { UserTitle } from "../Users/styles"
import { generateYearsBetween } from "../Common/generateBetweenYears"
import Loader from "../Common/Loader"
const url = `/api/v1`
const urlIndPR = `/api/v1/rondaIndicadores/get`

const New = ({ t }) => {
  const [match, params] = useRoute("/resultado/:ficha/ficha/:idFichaTecnica")
  const { ficha, idFichaTecnica } = params
  const { handleSubmit, formState, register, errors, reset, onChange } = useForm({
    mode: "all"
  })
  const { fields, isLoading } = useFields({ tabla: ficha })
  const [loading, setLoading] = useState(false)
  const { lista, valores } = useLista()
  const [checked, setChecked] = useState(false)
  const father = ficha.split("_resultados")[0]
  const [ind, setInd] = useState(null)
  const [editable, setEditable] = useState(false)

  const onSubmit = (data, e) => {
    e.preventDefault()
    setLoading(true)
    axiosService.create(`${url}/${ficha}`, data)
      .then(response => {
        if (response.status === 201) {
          setLoading(false)
          MyAlert({ title: t('message-successful'), icon: 'success' })
        }
      }).catch(error => {
        if (error.message === 'Request failed with status code 401') {
          setLoading(false)
        }
      })

    setTimeout(() => {
      reset()
      valores({ url: `/${ficha}/${idFichaTecnica}/lista` })
    }, 3000)
  }

  useEffect(() => {
    axiosService.getAll(`${url}/${father}/${idFichaTecnica}`)
      .then(response => response.data)
      .then(value => {
        setInd(value.idRegistroIndicadores)
      })
      .catch(error => {
        MyAlert({ title: t('message-connError'), icon: 'error' })
      })
  }, [father, idFichaTecnica, t])

  useEffect(() => {
    valores({ url: `/${ficha}/${idFichaTecnica}/lista` })
    register({ name: 'idFichaTecnica', type: 'hidden', value: idFichaTecnica })
  }, [valores, ficha, idFichaTecnica, register, reset])

  useEffect(() => {
    if (onChange)
      onChange(checked)
  }, [checked, onChange])

  useEffect(() => {
    const attributes = fields.map(f => f.nameField)
    reset(attributes)
  }, [reset, fields])

  const formValid = (type, nameField) => {
    return `${type} ${!(errors && errors[nameField]) && formState.touched[nameField] && 'is-valid'}`
  }

  useEffect(() => {
    if (ind) {
      axiosService.getAll(`${urlIndPR}/${ind}`)
        .then(response => response.data)
        .then(result => setEditable(result.editable))
        .catch(error => { })
    }
  }, [ind])

  return (
    <div>
      <Loader loading={isLoading} />
      <div>
        <UserTitle>{t('title-registroResultado')}</UserTitle>
        <hr />
      </div>
      {
        fields && fields.length > 0
          ?
          <form onSubmit={handleSubmit(onSubmit)} className="needs-validation">
            {
              JSON.parse(localStorage.getItem('jwt')) && JSON.parse(localStorage.getItem('jwt')).roles[0] !== 'ROLE_MODERATOR' && editable && <>
                <div className="mb-3 row">
                  {
                    fields.sort((a, b) => a.fieldOrder > b.fieldOrder ? 1 : -1)
                      .map(e => {
                        if (e.type === 'text' || e.type === 'date' || e.type === 'number' || e.type === 'float')
                          return (
                            <div className={e.col}>
                              <label className={e.ref ? 'form-label fw-bold' : 'form-label'}>{t(e.labelTag)}</label>
                              <span className="input-container">
                                <i><FontAwesomeIcon icon={e.icon ? e.icon : 'female'} /></i>
                                <input
                                  type={e.type === 'float' ? 'number' : e.type}
                                  name={e.nameField}
                                  className={formValid('form-control', e.nameField)}
                                  placeholder={i18next.language === 'es' ? e.descripcionCampo : e.descripcionCampoEn}
                                  ref={e.ref === 'required' ? register({ required: 'required-field' }) : register()}
                                  step={e.type === 'float' ? '0.01' : null}
                                  precision={e.type === 'float' ? '2' : null}
                                  disabled={e.disabled === 'true' ? !checked : null}
                                  min={e.type === 'float' || e.type === 'number' ? '0' : null}
                                />
                              </span>
                              <ErrorMessage errors={errors}
                                name={e.nameField}
                                render={({ message }) =>
                                  <small className="error">
                                    {t(message)}
                                  </small>}
                              />
                            </div>
                          )
                        if (e.type === 'select' || e.type === 'select-list')
                          return (
                            <div className={e.col}>
                              <label className={e.ref ? 'form-label fw-bold' : 'form-label'}>{t(e.labelTag)}</label>
                              <span className="input-container">
                                <i><FontAwesomeIcon icon={e.icon ? e.icon : 'female'} /></i>
                                <select
                                  name={e.type === 'select' ? `${e.nameField}[id]` : `${e.nameField}`}
                                  className={formValid('form-select', e.nameField)}
                                  ref={e.ref === 'required' ? register({ required: 'required-field' }) : register()}
                                >
                                  <option value="">{i18next.language === 'es' ? e.descripcionCampo : e.descripcionCampoEn}</option>
                                  {
                                    e.type === 'select' && <Opcion url={e.url} />
                                  }
                                  {
                                    e.type === 'select-list' && generateYearsBetween(2018).map(item => {
                                      return <option value={item}>{item}</option>
                                    })
                                  }
                                </select>
                              </span>
                              <ErrorMessage errors={errors}
                                name={e.type === 'select' ? `${e.nameField}[id]` : `${e.nameField}`}
                                render={({ message }) =>
                                  <small className="error">
                                    {t(message)}
                                  </small>}
                              />
                            </div>
                          )
                        if (e.type === 'multiple')
                          return (
                            <div className={e.col}>
                              <label className={e.ref ? 'form-label fw-bold' : 'form-label'}>{t(e.labelTag)}</label>
                              <span className="input-container">
                                <i><FontAwesomeIcon icon={e.icon ? e.icon : 'female'} /></i>
                                <GetOpcion url={e.url} ref={register()} nameField={e.nameField} />
                              </span>
                              <ErrorMessage errors={errors}
                                name={e.nameField}
                                render={({ message }) =>
                                  <small className="error">
                                    {t(message)}
                                  </small>}
                              />
                            </div>
                          )
                        if (e.type === 'boolean')
                          return (
                            <div className="form-check form-switch">
                              <input className="form-check-input" type="checkbox" id={e.nameField} name={e.nameField} ref={register()} onChange={e.disabled === 'true' ? f => { setChecked(f.target.checked) } : null} />
                              <label className="form-check-label" htmlFor={e.nameField}>{t(e.labelTag)}</label>
                            </div>
                          )
                        if (e.type === 'textarea')
                          return (
                            <div className={e.col}>
                              <label className={e.ref ? 'form-label fw-bold' : 'form-label'}>{t(e.labelTag)}</label>
                              <span className="input-container">
                                <i><FontAwesomeIcon icon={e.icon ? e.icon : 'female'} /></i>
                                <textarea
                                  name={e.nameField}
                                  className={formValid('form-control', e.nameField)}
                                  placeholder={i18next.language === 'es' ? e.descripcionCampo : e.descripcionCampoEn}
                                  ref={e.ref === 'required' ? register({ required: 'required-field' }) : register()}
                                  rows={3}
                                />
                              </span>
                              <ErrorMessage errors={errors}
                                name={e.nameField}
                                render={({ message }) =>
                                  <small className="error">
                                    {t(message)}
                                  </small>}
                              />
                            </div>
                          )
                        return null
                      })
                  }
                </div> </>
            }
            {
              fields && fields.length > 0 &&
              <div>
                <div className="row">
                  <div className="col-md-6 d-md-block">
                    {
                      JSON.parse(localStorage.getItem('jwt')) && JSON.parse(localStorage.getItem('jwt')).roles[0] !== 'ROLE_MODERATOR' && editable &&
                      <ButtonSubmit formState={formState} loading={loading} label={t('button-save')} />
                    }
                    <Link className="btn btn-secondary btn-lg" to={`/ficha/${father}/indicador/${ind}`}>{t('button-back')}</Link>
                  </div>
                </div>
              </div>
            }
          </form>
          : <div>
            <small>{t('message-noField')}</small>
            <Link className="btn btn-secondary btn-lg" to='/'>{t('button-back')}</Link>
          </div>
      }
      <Lista valores1={lista} campos={fields} tabla={ficha} t={t} idFichaTecnica={idFichaTecnica} editable={editable} />
    </div>
  )
}

function Opcion({ url }) {
  const [lista1, setLista1] = useState([])

  useEffect(() => {
    axiosService.getAll(`${url}`)
      .then(res => {
        setLista1(res.data)
      })
  }, [url])

  return (
    lista1.map(item => {
      return <option value={item.id} key={item.id}>{i18next.language === 'es' ? item.descripcion : item.descripcionEn}</option>
    })
  )
}

const GetOpcion = React.forwardRef(({ url, nameField }, ref) => {
  const [lista1, setLista1] = useState([])

  useEffect(() => {
    axiosService.getAll(`${url}`)
      .then(res => {
        const items = res.data
        setLista1(items)
        return () => {
          setLista1([])
        }
      })
  }, [url])

  return (
    <div style={{ height: '100px', overflowY: 'auto', width: '100%' }}>
      {
        lista1 && lista1.length > 0 && lista1.map(x => {
          return <li key={x.id} className="list-group-item">
            <input type="checkbox" ref={ref} name={`${nameField}[]`} id={`${nameField}[]`} value={x.id} />
            {" "}<label htmlFor={`${nameField}[]`}>{x.descripcion}</label>
          </li>
        })
      }
    </div>
  )
})

function Lista({ valores1, campos, tabla, t, idFichaTecnica, editable }) {
  const [arreglo, setArreglo] = useState([])

  function deleteFicha(e) {
    Swal.fire({
      title: t('message-confirm-delete'),
      text: t('message-advice-delete'),
      icon: 'warning',
      showDenyButton: true,
      confirmButtonColor: '#3085d6',
      denyButtonColor: '#d33',
      confirmButtonText: t('message-yes'),
      denyButtonText: t('message-no'),
    }).then((result) => {
      if (result.isConfirmed) {
        axiosService.remove(`${url}/${tabla}/${e}`)
          .then(response => {
            if (response.status === 204) {
              setArreglo(arreglo.filter(element => element.id !== e))
              MyAlert({ title: t('message-deleteSuccessful'), icon: 'success' })
            }
          }).catch(error => {
            if (error.message === 'Request failed with status code 500')
              MyAlert({ title: t('message-noDelete'), icon: 'error' })
          })
      }
    })
  }

  useEffect(() => {
    setArreglo(valores1)
  }, [valores1])

  return (
    <div>
      {
        arreglo && arreglo.length > 0
          ? <div>
            <hr className="my-4" />
            <h4>{t('label-records')}</h4>
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    {
                      campos.filter(response => response.list)
                        .map(ficha => {
                          const { labelTag } = ficha
                          return <th>{t(labelTag)}</th>
                        })
                    }
                    <th colSpan="2" style={{ textAlign: 'center' }}>{t('label-ops')}</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    arreglo.map(x => {
                      return <tr key={x.id}>
                        {
                          campos.filter(response => response.list)
                            .map(ficha => {
                              const item = Object.keys(x)
                                .filter(k => k === ficha.nameField)
                                .map(k => {
                                  switch (ficha.type) {
                                    case 'select':
                                      return x[k]['descripcion']
                                    case 'date':
                                      let full = new Date(x[k])
                                      let day = (full.getDate() + 1) < 10 ? `0${(full.getDate() + 1)}` : `${(full.getDate() + 1)}`
                                      let month = (full.getMonth() + 1) < 10 ? `0${(full.getMonth() + 1)}` : `${(full.getMonth() + 1)}`
                                      let year = full.getFullYear()
                                      return x[k] ? `${day}-${month}-${year}` : null
                                    default:
                                      return x[k]
                                  }
                                })
                              return (
                                <td>{item}</td>
                              )
                            })
                        }
                        {
                          JSON.parse(localStorage.getItem('jwt')) && JSON.parse(localStorage.getItem('jwt')).roles[0] !== 'ROLE_MODERATOR' && editable && <>
                            <td><button onClick={() => {
                              deleteFicha(x.id)
                            }}><FontAwesomeIcon icon={'trash-alt'} />
                            </button>
                            </td>
                            <td>
                              <Link to={`/resultado/${tabla}/edit/${x.id}/ficha/${idFichaTecnica}`}>
                                <FontAwesomeIcon icon={'edit'} />
                              </Link>
                            </td> </>
                        }
                        <td>
                          <Link to={`/resultado/${tabla}/show/${x.id}/ficha/${idFichaTecnica}`}>
                            <FontAwesomeIcon icon={'glasses'} />
                          </Link>
                        </td>
                      </tr>
                    })
                  }
                </tbody>
              </table>
            </div>
          </div>
          : <h5>{t('message-noList')}</h5>
      }
    </div>
  )
}

export default withNamespaces()(New)
