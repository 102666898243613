import { useEffect, useState } from "react"
import { utils, writeFileXLSX } from "xlsx"
import { axiosService } from "../../services/axiosService"
import { useRoute, Link } from 'wouter'
import { UserTitle } from "../Users/styles"
import { withNamespaces } from 'react-i18next'

function Indicadores({ t }) {
  const [match, params] = useRoute("/reporte/:modulo/pais/:pais")
  const { modulo, pais } = params
  const [indicadores, setIndicadores] = useState([])
  const [loading, setLoading] = useState(false)
  const [nombrePais, setNombrePais] = useState()
  const [nombreModulo, setNombreModulo] = useState()

  useEffect(() => {
    setLoading(true)
    if (modulo !== null && pais !== null) {
      axiosService.getAll(`/api/v1/rondaIndicadores/activos?idPais=${pais}&idModulo=${modulo}`)
        .then(result => {
          setIndicadores(result.data)
        }).finally(() => setLoading(false))
      axiosService.getAll(`/api/v1/paises/${pais}`)
        .then(p => setNombrePais(p.data.descripcion))
      axiosService.getAll(`/api/v1/modulos/${modulo}`)
        .then(m => setNombreModulo(m.data.descripcion))
    } else {
      setIndicadores([])
    }
  }, [modulo, pais])

  useEffect(() => {
    if (loading)
      setIndicadores([])
  }, [loading])

  return (
    <ListaModulos data={indicadores} t={t} pais={nombrePais} modulo={nombreModulo} />
  )
}

function ListaModulos({ data, t, pais, modulo }) {
  const [noRecord, setNoRecord] = useState([])
  const [record, setRecord] = useState([])

  useEffect(() => {
    data && data.length > 0 && data.forEach(item => {
      axiosService.getAll(`/api/v1/${item.indicador.tablaFichaTecnica}/report/${item.id}`)
        .then(result => {
          if (result.data && result.data.length > 0)
            setRecord(prevState => [...prevState, { data: result.data, tabla: item.indicador.tablaFichaTecnica }])
          else
            setNoRecord(prevState => [...prevState, {
              indicador: item.indicador.descripcion,
              modulo: item.indicador.modulo.descripcion,
              objeto: item.indicador.objeto.descripcion,
              nivel: item.indicador.nivel.descripcion,
              tipoIndicador: item.indicador.tipoIndicador.descripcion
            }])
        })
    })
  }, [data])

  return (
    <Resultados results={record} noResults={noRecord} t={t} pais={pais} modulo={modulo} />
  )
}

function Resultados({ results, noResults, t, pais, modulo }) {
  const [loading, setLoading] = useState(false)

  const lista = () => {
    setLoading(true)
    var workbook = utils.book_new(),
      worksheet = utils.json_to_sheet(noResults)
    utils.book_append_sheet(workbook, worksheet, "Sin Registros")

    results.forEach(x => {
      let arreglo = []
      x.data.forEach((repo) => {
        Object.entries(repo).forEach(([key, value]) => {
          let label = `label-${key}`
          
          if (label.slice(-2) === 'En'){
            label = t(label.split('En')[0])
            label = label.concat(' - ').concat(t('label-english'))
          } else {
            label = t(label)
          }

          arreglo.push({ campo: label?label:key, valor: value })
        });
        arreglo.push('')
      });

      let worksheet2 = utils.json_to_sheet(arreglo)
      utils.book_append_sheet(workbook, worksheet2, x.tabla)
    })
    writeFileXLSX(workbook, "Indicadores.xlsx")
    setLoading(false)
  }

  return (
    <div>
      <UserTitle>{t('title-reporteIndicadores')}</UserTitle>
      <hr />
      <div className="mb-3 row">
        <div className="form-group col-md-6">
          <label className="form-label fw-bold">{t('label-country')}</label>
          <span className="input-container">
            {pais}
          </span>
        </div>
        <div className="form-group col-md-6">
          <label className="form-label fw-bold">{t('label-module')}</label>
          <span className="input-container">
            {modulo}
          </span>
        </div>
      </div>
      <div>
        <button className="btn btn-primary btn-lg me-md-2" disabled={results.length === 0 && noResults.length === 0}
          onClick={async (e) => lista()}>{loading ? t('message-loading') : t('message-export')}</button>
        <Link className="btn btn-secondary btn-lg" to='/reporte'>{t('button-back')}</Link>
      </div>
    </div>
  )
}

export default withNamespaces()(Indicadores)