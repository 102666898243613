import { useRef, useState } from "react"
import { useForm } from "react-hook-form"
import { withNamespaces } from "react-i18next"
import MyAlert from "../../common/MyAlert"
import InputField from "../../inputs/InputField"
import { axiosService } from "../../services/axiosService"
import ButtonSubmit from "../Common/ButtonSubmit"
import { UserTitle } from "./styles"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ErrorMessage } from '@hookform/error-message'
const url = `/api/v1/users`

function ResetPwd({ t }) {
  const { register, errors, handleSubmit, formState, watch } = useForm({ mode: "all" })
  const password = useRef({})
  password.current = watch("password", "")
  const [loading, setLoading] = useState(false)
  const idUser = JSON.parse(localStorage.getItem('jwt')) ? JSON.parse(localStorage.getItem('jwt')).id : null

  const onSubmit = (data, e) => {
    e.preventDefault()
    setLoading(true)
    axiosService.update(`${url}/${idUser}/pwd`, data)
      .then(response => {
        if (response.status === 200) {
          setLoading(false)
          MyAlert({ title: t('message-successful'), icon: 'success' })
        }
      }).catch(error => {
        if (error.message === 'Request failed with status code 401') {
          MyAlert({ title: t('message-userUnauthorized'), icon: 'error' })
        } else if (error.message === 'Network Error') {
          MyAlert({ title: t('message-connError'), icon: 'error' })
        }
      }).finally(() => setLoading(false))
  }

  return (
    <div>
      <UserTitle>{t('title-updatePwd')}</UserTitle>
      <hr className="my-4" />
      <form onSubmit={handleSubmit(onSubmit)} className="needs-validation">
        <div className="mb-3 row">
          <div className="form-group col-md-6">
            <label className='font-weight-normal'>{t('label-password')}</label>
            <span className="input-container">
              <i><FontAwesomeIcon icon={['fas', 'key']} /></i>
              <input type="password" className="form-control" name="password"
                ref={register({
                  required: "required-field",
                  minLength: {
                    value: 8,
                    message: t('message-passwordLength')
                  },
                  pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,15}$/
                })} />
            </span>
            <ErrorMessage errors={errors} name="password" render={({ message }) => <p className="error"><small>{t(message)}</small></p>} />
          </div>
          <InputField name="password2" type="password" label={t('label-password2')} errors={errors} col="form-group col-md-6" icon="key"
            ref={register({
              required: "required-field",
              validate: value => value === password.current || t('message-passwordMatch')
            })} />
        </div>
        <div className="mb-3 row">
          <div className="form-group col-md-6">
            <small>{t('message-pwdConditions')}</small>
          </div>
        </div>
        <ButtonSubmit formState={formState} loading={loading} label={t('button-update')} />
      </form>
    </div>
  )
}

export default withNamespaces()(ResetPwd)