import { useState, useEffect } from "react"
import { axiosService } from "../services/axiosService"
import useError from "./useError"
const _url = `/api/v1/registroIndicadores`

function useRegistros() {
  const [state, setState] = useState({ loading: false })
  const { conn, auth, getError } = useError()
  const [registros, setRegistros] = useState([])

  useEffect(() => {
    setState({ loading: true })
    axiosService.getAll(_url)
      .then(item => {
        setRegistros(item.data)
      }).catch(err => {
        getError({ error: err })
      }).then(setState({ loading: false }))
  }, [setRegistros, getError])

  return {
    isLoading: state.loading,
    connError: conn,
    authError: auth,
    registros
  }
}

export default useRegistros