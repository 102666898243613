import { useEffect, useState } from "react"
import { axiosService } from "../services/axiosService"
import useError from "./useError"
const _url = `/api/v1/rondas`

function useRondas() {
  const { conn, auth, getError } = useError()
  const [rondas, setRondas] = useState([])

  useEffect(() => {
    axiosService.getAll(_url)
      .then(item => {
        setRondas(item.data.map(result => {
          return {
            id: result.id,
            descripcion: result.descripcion.concat(' / ').concat(result.fechaDesde).concat(' : ').concat(result.fechaHasta)
          }
        }))
      }).catch(err => {
        getError({ error: err })
      })
  }, [setRondas, getError])

  return {
    connError: conn,
    authError: auth,
    rondas
  }
}

export default useRondas