import { useEffect, useState } from "react"
import { axiosService } from "../services/axiosService"
import useError from "./useError"
const _url = `/api/v1/rondaIndicadores`

function useIndicadoresRonda() {
  const [state, setState] = useState({ loading: false })
  const [indicadores, setIndicadores] = useState([])
  const { conn, auth, getError } = useError()

  useEffect(() => {
    setState({ loading: true })
    axiosService.getAll(`${_url}/${JSON.parse(localStorage.getItem('idPais'))}`)
      .then(result => {
        setIndicadores(result.data)
      }).catch(err => {
        getError({ error: err })
      }).finally(() => setState({ loading: false }))
  }, [getError, setIndicadores])

  return {
    isLoading: state.loading,
    connError: conn,
    authError: auth,
    indicadores
  }
}

export default useIndicadoresRonda