import { useEffect, useState } from "react";
import { axiosService } from "../services/axiosService";
import useError from "./useError";
const _url = `/api/v1/indicadores/tabla`

function useIndicadorTabla({ url }) {
  const [indicador, setIndicador] = useState({
    descripcion: '',
    modulo: '',
    objeto: '',
    nivel: '',
    tipoIndicador: '',
    fuentesSugeridas: '',
    notaExplicativa: '',
    descripcionEn: '',
    fuentesSugeridasEn: '',
    notaExplicativaEn: ''
  })
  const { conn, auth, getError } = useError()

  useEffect(() => {
    axiosService.getAll(`${_url}/${url}`)
      .then(result => {
        const { descripcion, modulo, objeto, nivel, tipoIndicador, fuentesSugeridas, notaExplicativa, descripcionEn, fuentesSugeridasEn, notaExplicativaEn } = result.data
        setIndicador({ descripcion, modulo, objeto, nivel, tipoIndicador, fuentesSugeridas, notaExplicativa, descripcionEn, fuentesSugeridasEn, notaExplicativaEn })
      }).catch(error => {
        getError({ error: error })
      })
  }, [getError, url])

  return {
    indicador,
    auth: auth,
    conn: conn
  }
}

export default useIndicadorTabla