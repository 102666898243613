import { useState, useEffect, useContext } from "react"
import Context from '../context/UserListContext'
import { axiosService } from "../services/axiosService"
import useError from "./useError"
const _url = `/api/v1/users`

function ListUsers() {
  const [state, setState] = useState({ loading: false })
  const { conn, auth, getError } = useError()
  const { users, setUsers } = useContext(Context)

  useEffect(() => {
    setState({ loading: true })
    axiosService.getAll(_url)
      .then(item => {
        setUsers(item.data)
      }).catch(err => {
        getError({ error: err })
      }).then(setState({ loading: false }))
  }, [setUsers, getError])

  return {
    isLoading: state.loading,
    connError: conn,
    authError: auth,
    users
  }
}

export default ListUsers