import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { UserTitle } from './components/Users/styles'

const NotFound = () => (
  <div>
    <div className="row">
      <div className="col">
        <UserTitle>Not found!</UserTitle>
        <div style={{ fontSize: '150px', textAlign: 'center' }}><FontAwesomeIcon icon="thumbs-down" size="xs" /></div>
      </div>
    </div>
  </div>
)

export default NotFound