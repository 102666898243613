import axios from "axios"
import { config } from '../Constants'
const url = `${config.url.REACT_APP_DOMAIN}`

const instance = axios.create({
  baseURL: url,
  headers: {
    "Content-Type": "application/json",
  },
})

instance.interceptors.request.use(
  (config) => {
    const token = JSON.parse(localStorage.getItem('token'))
    if (token) {
      config.headers["Authorization"] = 'Bearer ' + token
    }
    return config
  },
  (error) => {
    return Promise.reject(error);
  }
)

instance.interceptors.response.use(
  (res) => {
    return res
  },
  async (err) => {
    const originalConfig = err.config
    if (originalConfig?.url !== "/api/auth/signin" && err?.response) {
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true
        try {
          const rs = await instance.post("/api/auth/refresh", {
            accessToken: JSON.parse(localStorage.getItem('token')),
          })
          localStorage.setItem('token', JSON.stringify(rs.data))
          return instance(originalConfig)
        } catch (_error) {
          return Promise.reject(_error)
        }
      }
    }
    return Promise.reject(err)
  }
)

export default instance