import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { withNamespaces } from 'react-i18next'
import { Link, useRoute } from 'wouter'
import useFields from '../../hooks/useFields'
import useIndicadorTabla from '../../hooks/useIndicadorTabla'
import { axiosService } from '../../services/axiosService'
import Header from './Header'
import i18next from 'i18next'
import { generateYearsBetween } from '../Common/generateBetweenYears'
import Loader from '../Common/Loader'
import MyAlert from '../../common/MyAlert'

function Show({ t }) {
  const [match, params] = useRoute("/ficha/:tabla/show/:pk/indicador/:ind")
  const { pk, tabla, ind } = params

  const { register, reset } = useForm({
    mode: "all"
  })
  const { fields } = useFields({ tabla: tabla })
  const { indicador } = useIndicadorTabla({ url: tabla })
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    axiosService.getAll(`/api/v1/${tabla}/${pk}`)
      .then(result => reset(result.data))
      .catch(error => {
        if (error?.response?.status === 404) {
          MyAlert({ title: t('message-notRecord'), icon: 'error' })
        }
      })
      .finally(() => setLoading(false))
  }, [tabla, pk, reset, t])

  return (
    <div>
      <Loader loading={loading} />
      {
        fields && fields.length > 0 ?
          <><Header
            indicador={indicador}
            title={t('label-indicator')}
            mode={t('title-consulta')}
            code={ind}
          />
            <div className="mb-3 row">
              <input name="idRegistroIndicadores" type="hidden" value={ind} ref={register()} />
              {
                fields.sort((a, b) => a.fieldOrder > b.fieldOrder ? 1 : -1)
                  .map(e => {
                    if (e.type === 'text' || e.type === 'date' || e.type === 'number' || e.type === 'float')
                      return (
                        <div className={e.col} key={e.id}>
                          <label className={e.ref ? 'form-label fw-bold' : 'form-label'}>{t(e.labelTag)}</label>
                          <span className="input-container">
                            <i><FontAwesomeIcon icon={e.icon ? e.icon : 'female'} /></i>
                            <input
                              type={e.type === 'float' ? 'number' : e.type}
                              name={e.nameField}
                              className="form-control"
                              placeholder={i18next.language === 'es' ? e.descripcionCampo : e.descripcionCampoEn}
                              ref={e.ref === 'required' ? register({ required: 'required-field' }) : register()}
                              step={e.type === 'float' ? '0.01' : null}
                              precision={e.type === 'float' ? '2' : null}
                              disabled={true}
                              min={e.type === 'float' || e.type === 'number' ? '0' : null}
                            />
                          </span>

                        </div>
                      )
                    if (e.type === 'select' || e.type === 'select-list')
                      return (
                        <div className={e.col}>
                          <label className={e.ref ? 'form-label fw-bold' : 'form-label'}>{t(e.labelTag)}</label>
                          <span className="input-container">
                            <i><FontAwesomeIcon icon={e.icon ? e.icon : 'female'} /></i>
                            <select
                              name={e.type === 'select' ? `${e.nameField}[id]` : `${e.nameField}`}
                              className="form-select"
                              ref={e.ref === 'required' ? register({ required: 'required-field' }) : register()}
                              disabled={true}
                            >
                              <option value="">{e.descripcionCampo}</option>
                              {
                                e.type === 'select' && <Opcion url={e.url} />
                              }
                              {
                                e.type === 'select-list' && generateYearsBetween(2018).map(item => {
                                  return <option value={item} key={item}>{item}</option>
                                })
                              }
                            </select>
                          </span>

                        </div>
                      )
                    if (e.type === 'multiple')
                      return (
                        <div className={e.col}>
                          <label className={e.ref ? 'form-label fw-bold' : 'form-label'}>{t(e.labelTag)}</label>
                          <span className="input-container">
                            <i><FontAwesomeIcon icon={e.icon ? e.icon : 'female'} /></i>
                            <GetOpcion url={e.url} ref={register()} nameField={e.nameField} />
                          </span>

                        </div>
                      )
                    if (e.type === 'boolean')
                      return (
                        <div className="form-check form-switch">
                          <input disabled={true} className="form-check-input" type="checkbox" id={e.nameField} name={e.nameField} ref={register()} />
                          <label className="form-check-label" htmlFor={e.nameField}>{t(e.labelTag)}</label>
                        </div>
                      )
                    if (e.type === 'textarea')
                      return (
                        <div className={e.col}>
                          <label className={e.ref ? 'form-label fw-bold' : 'form-label'}>{t(e.labelTag)}</label>
                          <span className="input-container">
                            <i><FontAwesomeIcon icon={e.icon ? e.icon : 'female'} /></i>
                            <textarea
                              name={e.nameField}
                              className="form-control"
                              placeholder={i18next.language === 'es' ? e.descripcionCampo : e.descripcionCampoEn}
                              ref={e.ref === 'required' ? register({ required: 'required-field' }) : register()}
                              rows={3}
                              disabled={true}
                            />
                          </span>

                        </div>
                      )
                    return null
                  })
              }
            </div>
            {
              fields && fields.length > 0 &&
              <div>
                <div className="row">
                  <div className="col-md-6 d-md-block mb-2">
                    <Link className="btn btn-secondary btn-lg" to={`/ficha/${tabla}/indicador/${ind}`}>{t('button-back')}</Link>
                  </div>
                </div>
              </div>
            }
          </>
          : <div className='mb-2'>
            <small>{t('message-noField')}</small>
            <Link className="btn btn-secondary btn-lg" to='/'>{t('button-back')}</Link>
          </div>
      }
    </div>
  )
}

function Opcion({ url }) {
  const [lista1, setLista1] = useState([])

  useEffect(() => {
    axiosService.getAll(`${url}`)
      .then(res => {
        const items = res.data
        setLista1(items)
        return () => {
          setLista1([])
        }
      })
  }, [url])

  return (
    lista1.map(item => {
      return <option key={item.id} value={item.id}>{i18next.language === 'es' ? item.descripcion : item.descripcionEn}</option>
    })
  )
}

const GetOpcion = React.forwardRef(({ url, nameField }, ref) => {
  const [lista1, setLista1] = useState([])

  useEffect(() => {
    axiosService.getAll(`${url}`)
      .then(res => {
        const items = res.data
        setLista1(items)
        return () => {
          setLista1([])
        }
      })
  }, [url])

  return (
    <div style={{ height: '100px', overflowY: 'auto', width: '100%' }}>
      {
        lista1 && lista1.length > 0 && lista1.map(x => {
          return <div key={x.id} className="form-check m-2">
            <input disabled={true} type="checkbox" ref={ref} name={`${nameField}[]`} id={x.id} value={x.id} className="form-check-input" />
            <label htmlFor={x.id} className="form-check-label">{x.descripcion}</label>
          </div>
        })
      }
    </div>
  )
})

export default withNamespaces()(Show)