import { forwardRef } from "react";

const RadioButtonField = forwardRef((props, ref) => (
  <div className="form-check">
    <input className="form-check-input" type="radio" name={props.name} value={props.value.id} id={props.id} ref={ref} />
    <label className="form-check-label" htmlFor={props.id}>
      {props.value.descripcion}
    </label>
  </div>
))

export default RadioButtonField