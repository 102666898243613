import { useForm } from "react-hook-form"
import useFields from "../../hooks/useFields"
import ButtonSubmit from "../Common/ButtonSubmit"
import { withNamespaces } from 'react-i18next'
import React, { useEffect, useState } from "react"
import { axiosService } from "../../services/axiosService"
import MyAlert from "../../common/MyAlert"
import { ErrorMessage } from "@hookform/error-message"
import { Link, useLocation, useRoute } from "wouter"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useLista from "../../hooks/useLista"
import Header from "./Header"
import useIndicadorTabla from "../../hooks/useIndicadorTabla"
import i18next from 'i18next'
import Swal from 'sweetalert2'
import { generateYearsBetween } from "../Common/generateBetweenYears"
import Loader from "../Common/Loader"

const url = `/api/v1`
const urlNext = `/api/v1/rondaIndicadores/next`
const urlPrev = `/api/v1/rondaIndicadores/prev`
const urlIndPR = `/api/v1/rondaIndicadores/get`

const Ficha = ({ t }) => {
  const [match, params] = useRoute("/ficha/:pk/indicador/:ind")
  const { pk, ind } = params
  const { handleSubmit, formState, register, errors, reset, onChange } = useForm({
    mode: "all"
  })
  const { indicador, auth, conn } = useIndicadorTabla({ url: pk })
  const { fields, isLoading } = useFields({ tabla: pk })
  const [loading, setLoading] = useState(false)
  const [buttonLoad, setButtonLoad] = useState(false)
  const { lista, valores, connError } = useLista()
  const [, setLocation] = useLocation()
  const [checked, setChecked] = useState(false)
  const [next, setNext] = useState(null)
  const [prev, setPrev] = useState(null)
  const [editable, setEditable] = useState(false)

  const onSubmit = (data, e) => {
    e.preventDefault()
    setLoading(true)
    axiosService.create(`${url}/${pk}`, data)
      .then(response => {
        if (response.status === 201) {
          MyAlert({ title: t('message-successful'), icon: 'success' })
        }
      }).catch(error => {
        if (error.message === 'Request failed with status code 401') {
          MyAlert({ title: t('message-userUnauthorized'), icon: 'error' })
        } else if (error.message === 'Network Error') {
          MyAlert({ title: t('message-connError'), icon: 'error' })
        }
      }).finally(() => setLoading(false))

    setTimeout(() => {
      reset()
      valores({ url: `/rondaIndicadores/${ind}/${pk}` })
    }, 3000)
  }

  useEffect(() => {
    if (conn) {
      setLocation("/noconn")
      MyAlert({ title: t('message-connError'), icon: 'error' })
    } else if (auth || !JSON.parse(localStorage.getItem('jwt'))) {
      setLocation("/")
      MyAlert({ title: t('message-userUnauthorized'), icon: 'error' })
    }
  }, [t, setLocation, auth, conn, reset])

  useEffect(() => {
    valores({ url: `/rondaIndicadores/${ind}/${pk}` })
    if (connError)
      setLocation('/notfound')
    register({ name: 'idRegistroIndicadores', type: 'hidden', value: ind })
  }, [valores, pk, ind, register, reset, connError, setLocation])

  useEffect(() => {
    if (onChange)
      onChange(checked)
  }, [checked, onChange])

  useEffect(() => {
    const attributes = fields.map(f => f.nameField)
    reset(attributes)
  }, [reset, fields])

  useEffect(() => {
    if (ind) {
      setButtonLoad(true)
      axiosService.getAll(`${urlIndPR}/${ind}`)
        .then(response => {
          const { editable } = response.data
          setEditable(editable)
        })

      axiosService.getAll(`${urlNext}?idPais=${JSON.parse(localStorage.getItem('idPais'))}&idCurrent=${ind}`)
        .then(response => setNext(response.data))
        .catch(error => setNext(null))

      axiosService.getAll(`${urlPrev}?idPais=${JSON.parse(localStorage.getItem('idPais'))}&idCurrent=${ind}`)
        .then(response => setPrev(response.data))
        .catch(error => setPrev(null))
      setButtonLoad(false)
    }
  }, [ind])

  const formValid = (type, nameField) => {
    return `${type} ${!(errors && errors[nameField]) && formState.touched[nameField] && 'is-valid'}`
  }

  return (
    <div>
      <Loader loading={isLoading} />
      <Header
        indicador={indicador}
        title={t('label-indicator')}
        mode={t('label-register')}
      />
      {
        fields && fields.length > 0
          ?
          <form onSubmit={handleSubmit(onSubmit)} className="needs-validation">
            <input name="indicadorPorRonda[id]" type="hidden" value={ind} ref={register()} />
            {
              JSON.parse(localStorage.getItem('jwt')) && JSON.parse(localStorage.getItem('jwt')).roles[0] !== 'ROLE_MODERATOR' && editable && (next || prev) && <>
                <div className="mb-3 row">
                  {
                    fields.sort((a, b) => a.fieldOrder > b.fieldOrder ? 1 : -1)
                      .map(e => {
                        if (e.type === 'text' || e.type === 'date' || e.type === 'number' || e.type === 'float')
                          return (
                            <div className={e.col} key={e.id}>
                              <label className={e.ref ? 'form-label fw-bold' : 'form-label'}>{t(e.labelTag)}</label>
                              <span className="input-container">
                                <i><FontAwesomeIcon icon={e.icon ? e.icon : 'female'} /></i>
                                <input
                                  type={e.type === 'float' ? 'number' : e.type}
                                  name={e.nameField}
                                  className={formValid('form-control', e.nameField)}
                                  placeholder={i18next.language === 'es' ? e.descripcionCampo : e.descripcionCampoEn}
                                  ref={e.ref === 'required' ? register({ required: 'required-field' }) : register()}
                                  step={e.type === 'float' ? '0.01' : null}
                                  precision={e.type === 'float' ? '2' : null}
                                  disabled={e.disabled === 'true' ? !checked : null}
                                  min={e.type === 'float' || e.type === 'number' ? '0' : null}
                                  max={e.type === 'float' || e.type === 'number' ? '9999999999999999999' : null}
                                />
                              </span>
                              <ErrorMessage errors={errors}
                                name={e.nameField}
                                render={({ message }) =>
                                  <small className="error">
                                    {t(message)}
                                  </small>}
                              />
                            </div>
                          )
                        if (e.type === 'select' || e.type === 'select-list')
                          return (
                            <div className={e.col}>
                              <label className={e.ref ? 'form-label fw-bold' : 'form-label'}>{t(e.labelTag)}</label>
                              <span className="input-container">
                                <i><FontAwesomeIcon icon={e.icon ? e.icon : 'female'} /></i>
                                <select
                                  name={e.type === 'select' ? `${e.nameField}[id]` : `${e.nameField}`}
                                  className={formValid('form-select', e.nameField)}
                                  ref={e.ref === 'required' ? register({ required: 'required-field' }) : register()}
                                >
                                  <option value="">{i18next.language === 'es' ? e.descripcionCampo : e.descripcionCampoEn}</option>
                                  {
                                    e.type === 'select' && <Opcion url={e.url} />
                                  }
                                  {
                                    e.type === 'select-list' && generateYearsBetween(2018).map(item => {
                                      return <option key={item} value={item}>{item}</option>
                                    })
                                  }
                                </select>
                              </span>
                              <ErrorMessage errors={errors}
                                name={e.type === 'select' ? `${e.nameField}[id]` : `${e.nameField}`}
                                render={({ message }) =>
                                  <small className="error">
                                    {t(message)}
                                  </small>}
                              />
                            </div>
                          )
                        if (e.type === 'multiple')
                          return (
                            <div className={e.col}>
                              <label className={e.ref ? 'form-label fw-bold' : 'form-label'}>{t(e.labelTag)}</label>
                              <span className="input-container">
                                <i><FontAwesomeIcon icon={e.icon ? e.icon : 'female'} /></i>
                                <GetOpcion url={e.url} ref={register()} nameField={e.nameField} />
                              </span>
                              <ErrorMessage errors={errors}
                                name={e.nameField}
                                render={({ message }) =>
                                  <small className="error">
                                    {t(message)}
                                  </small>}
                              />
                            </div>
                          )
                        if (e.type === 'boolean')
                          return (
                            <div className="form-check form-switch">
                              <input className="form-check-input" type="checkbox" id={e.nameField} name={e.nameField} ref={register()} onChange={e.disabled === 'true' ? f => { setChecked(f.target.checked) } : null} />
                              <label className="form-check-label" htmlFor={e.nameField}>{t(e.labelTag)}</label>
                            </div>
                          )
                        if (e.type === 'textarea')
                          return (
                            <div className={e.col}>
                              <label className={e.ref ? 'form-label fw-bold' : 'form-label'}>{t(e.labelTag)}</label>
                              <span className="input-container">
                                <i><FontAwesomeIcon icon={e.icon ? e.icon : 'female'} /></i>
                                <textarea
                                  name={e.nameField}
                                  className={formValid('form-control', e.nameField)}
                                  placeholder={i18next.language === 'es' ? e.descripcionCampo : e.descripcionCampoEn}
                                  ref={e.ref === 'required' ? register({ required: 'required-field' }) : register()}
                                  rows={3}
                                />
                              </span>
                              <ErrorMessage errors={errors}
                                name={e.nameField}
                                render={({ message }) =>
                                  <small className="error">
                                    {t(message)}
                                  </small>}
                              />
                            </div>
                          )
                        return null
                      })
                  }
                </div> </>
            }
            {
              fields && fields.length > 0 &&
              <div>
                <div className="row">
                  <div className="col-md-6 d-md-block">
                    {
                      JSON.parse(localStorage.getItem('jwt')) && JSON.parse(localStorage.getItem('jwt')).roles[0] !== 'ROLE_MODERATOR' && editable &&
                      <ButtonSubmit formState={formState} loading={loading} label={t('button-save')} />
                    }
                    <Link className="btn btn-secondary btn-lg" to='/firstPage'>{t('button-goToIndicadores')}</Link>
                  </div>
                  <div className="col-md-6 d-md-flex justify-content-md-end">
                    {
                      prev ? <Link className="btn btn-secondary btn-lg me-md-2" to={prev}>
                        <FontAwesomeIcon icon={'arrow-left'} className="fa-1x" />{" "}{!buttonLoad ? t('button-anterior') : t('message-loading')}
                      </Link> : null
                    }
                    {
                      next ? <Link className="btn btn-secondary btn-lg" to={next}>
                        {!buttonLoad ? t('button-siguiente') : t('message-loading')}{" "}<FontAwesomeIcon icon={'arrow-right'} className="fa-1x" />
                      </Link> : null
                    }
                  </div>
                </div>
              </div>
            }
          </form>
          : <div>
            <small>{t('message-noField')}</small>
            <Link className="btn btn-secondary btn-lg" to='/firstPage'>{t('button-goToIndicadores')}</Link>
          </div>
      }
      <Lista valores1={lista} campos={fields} tabla={pk} t={t} editable={editable} />
    </div>
  )
}

function Opcion({ url }) {
  const [lista1, setLista1] = useState([])

  useEffect(() => {
    axiosService.getAll(`${url}`)
      .then(res => {
        setLista1(res.data)
        return () => {
          setLista1([])
        }
      })
  }, [url])

  return (
    lista1.map(item => {
      return <option key={item.id} value={item.id}>{i18next.language === 'es' ? item.descripcion : item.descripcionEn}</option>
    })
  )
}

const GetOpcion = React.forwardRef(({ url, nameField }, ref) => {
  const [lista1, setLista1] = useState([])

  useEffect(() => {
    axiosService.getAll(`${url}`)
      .then(res => {
        setLista1(res.data)
        return () => {
          setLista1([])
        }
      })
  }, [url])

  return (
    <div style={{ height: '100px', overflowY: 'scroll', width: '100%' }}>
      {
        lista1 && lista1.length > 0 && lista1.map(x => {
          return <div key={x.id} className="form-check m-2">
            <input type="checkbox" ref={ref} name={`${nameField}[]`} id={x.id} value={x.id} className="form-check-input" />
            <label htmlFor={x.id} className="form-check-label">{i18next.language === 'es' ? x.descripcion : x.descripcionEn}</label>
          </div>
        })
      }
    </div>
  )
})

function Lista({ valores1, campos, tabla, t, editable }) {
  const [arreglo, setArreglo] = useState([])

  function deleteFicha(e) {
    Swal.fire({
      title: t('message-confirmDelete'),
      text: t('message-adviceDelete'),
      icon: 'warning',
      showDenyButton: true,
      confirmButtonColor: '#3085d6',
      denyButtonColor: '#d33',
      confirmButtonText: t('message-yes'),
      denyButtonText: t('message-no'),
    }).then((result) => {
      if (result.isConfirmed) {
        axiosService.remove(`${url}/${tabla}/${e}`)
          .then(response => {
            if (response.status === 204) {
              setArreglo(arreglo.filter(element => element.id !== e))
              MyAlert({ title: t('message-deleteSuccessful'), icon: 'success' })
            }
          }).catch(error => {
            if (error.message === 'Request failed with status code 500')
              MyAlert({ title: t('message-noDelete'), icon: 'error' })
          })
      }
    })
  }

  useEffect(() => {
    setArreglo(valores1)
  }, [valores1])

  return (
    <div>
      {
        arreglo && arreglo.length > 0
          ? <div>
            <hr className="my-4" />
            <h4>{t('label-records')}</h4>
            <div className="table-responsive">
              <table className="table table-hover">
                <thead>
                  <tr>
                    {
                      campos.filter(response => response.list)
                        .map(ficha => {
                          const { labelTag } = ficha
                          return <th key={labelTag}>{t(labelTag)}</th>
                        })
                    }
                    {
                      (tabla === 'ficha_tecnica_259' || tabla === 'ficha_tecnica_260') &&
                      <th>{t('label-resultados')}</th>
                    }
                    <th colSpan={JSON.parse(localStorage.getItem('jwt')) && JSON.parse(localStorage.getItem('jwt')).roles[0] !== 'ROLE_MODERATOR' && editable ? '3' : '1'} style={{ textAlign: 'center' }}>{t('label-ops')}</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    arreglo.map(x => {
                      return <tr>
                        {
                          campos.filter(response => response.list)
                            .map(ficha => {
                              const item = Object.keys(x)
                                .filter(k => k === ficha.nameField)
                                .map(k => {
                                  switch (ficha.type) {
                                    case 'select':
                                      return x[k]['descripcion']
                                    case 'date':
                                      return x[k].toLocaleString()
                                    default:
                                      return x[k]
                                  }
                                })
                              return (
                                <td>{item}</td>
                              )
                            })
                        }
                        {
                          (tabla === 'ficha_tecnica_259' || tabla === 'ficha_tecnica_260') &&
                          <td>
                            <Link to={`/resultado/${tabla}_resultados/ficha/${x.id}`} style={{ textDecoration: 'none' }}>
                              {t('label-resultados')}
                            </Link>
                          </td>
                        }
                        {
                          JSON.parse(localStorage.getItem('jwt')) && JSON.parse(localStorage.getItem('jwt')).roles[0] !== 'ROLE_MODERATOR' && editable && <>
                            <td>
                              <button onClick={() => {
                                deleteFicha(x.id)
                              }}><FontAwesomeIcon icon={'trash-alt'} />
                              </button>
                            </td>
                            <td>
                              <Link to={`/ficha/${tabla}/edit/${x.id}/indicador/${x.indicadorPorRonda.id}`}>
                                <FontAwesomeIcon icon={'edit'} />
                              </Link>
                            </td></>
                        }
                        <td>
                          <Link to={`/ficha/${tabla}/show/${x.id}/indicador/${x.indicadorPorRonda.id}`}>
                            <FontAwesomeIcon icon={'glasses'} />
                          </Link>
                        </td>
                      </tr>
                    })
                  }
                </tbody>
              </table>
            </div>
          </div>
          : <h5>{t('message-noList')}</h5>
      }
    </div>
  )
}

export default withNamespaces()(Ficha)