import { config } from '../Constants'
const _url = `${config.url.REACT_APP_DOMAIN}/api/auth/signup`

export default async function registerUser({ data }) {
  return fetch(_url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  }).then(res => {
    return res.json()
  }).then(res => {
    return res
  }).catch(err => {
    throw new Error(err)
  })
}