import React, { memo, useEffect, useState } from "react";
import {
  ZoomableGroup,
  ComposableMap,
  Geographies,
  Geography
} from "react-simple-maps";
import { axiosService } from "../../services/axiosService";
import Loader from "../Common/Loader";

const MapChart = ({ setTooltipContent }) => {
  const [registros, setRegistros] = useState([]);
  const [loading, setLoading] = useState(false);

  const total = (id, name) => {
    let cantidad = 0;
    if (id && registros)
      cantidad = Number(registros.filter(f => f.siglas === id).map(x => x.cantidad));

    return cantidad > 0 ? name + ": " + cantidad.toString() : name;
  }

  useEffect(() => {
    setLoading(true);
    axiosService.getAll("/api/v1/paises/get_registro")
      .then(response =>
        setRegistros(response.data)
      ).finally(() => setLoading(false));
  }, [])

  return (
    <div data-tip="">
      <Loader loading={loading} />
      <ComposableMap>
        <ZoomableGroup>
          <Geographies geography="/features.json">
            {({ geographies }) =>
              geographies.map((geo) => (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  onMouseEnter={() => {
                    setTooltipContent(total(geo.id, geo.properties.name));
                  }}
                  onMouseLeave={() => {
                    setTooltipContent("");
                  }}
                  style={{
                    default: {
                      fill: "#D6D6DA",
                      outline: "none"
                    },
                    hover: {
                      fill: "#F53",
                      outline: "none"
                    },
                    pressed: {
                      fill: "#E42",
                      outline: "none"
                    }
                  }}
                />
              ))
            }
          </Geographies>
        </ZoomableGroup>
      </ComposableMap>
    </div>
  );
};

export default memo(MapChart);