import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ErrorMessage } from "@hookform/error-message"
import { useEffect, useState } from "react"
import { Table } from "react-bootstrap"
import { useForm } from "react-hook-form"
import { withNamespaces } from "react-i18next"
import Swal from "sweetalert2"
import { Link, useLocation, useRoute } from "wouter"
import MyAlert from "../../common/MyAlert"
import useIndicadoresRonda from "../../hooks/useIndicadoresRonda"
import { axiosService } from "../../services/axiosService"
import ButtonSubmit from "../Common/ButtonSubmit"
import { generateYearsBetween } from "../Common/generateBetweenYears"
import { UserTitle } from "../Users/styles"
const urlSubmit = `/api/v1/fichaNacimientos`

const NewFichaNacimientos = ({ t }) => {
  const [match, params] = useRoute("/ficha/:pk/fichaNacimientos")
  const { pk } = params
  const { handleSubmit, formState, register, errors, reset } = useForm({
    mode: "all"
  })
  const [loading, setLoading] = useState(false)
  const { connError, authError } = useIndicadoresRonda()
  const [, setLocation] = useLocation()
  const anios = generateYearsBetween(2018)
  const [lista, setLista] = useState([])

  const onSubmit = (data, e) => {
    e.preventDefault()
    setLoading(true)
    axiosService.create(urlSubmit, data)
      .then(response => {
        if (response.status === 201) {
          MyAlert({ title: t('message-successful'), icon: 'success' })
          reset()
        }
      }).catch(error => {
        if (error.message === 'Request failed with status code 401') {
          MyAlert({ title: t('message-userUnauthorized'), icon: 'error' })
        } else if (error.message === 'Request failed with status code 500') {
          MyAlert({ title: t('message-noRecordAnio'), icon: 'error' })
        }
      }).finally(() => {
        setTimeout(() => {
          setLoading(false)
        }, 3000)
      })
  }

  useEffect(() => {
    if (connError) {
      setLocation("/noconn")
      MyAlert({ title: t('message-connError'), icon: 'error' })
    } else if (authError) {
      setLocation("/")
      MyAlert({ title: t('message-userUnauthorized'), icon: 'error' })
    }
  }, [authError, connError, setLocation, t, reset])

  useEffect(() => {
    axiosService.getAll(`${urlSubmit}/ficha/${pk}`)
      .then(response => setLista(response.data))
      .catch(error => { })
  }, [pk, loading])

  return (
    <div>
      <div className="row">
        <div className="col">
          <UserTitle>{t('title-fichaNacimientos')}</UserTitle>
          <hr />
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="needs-validation">
        {
          JSON.parse(localStorage.getItem('jwt')) && JSON.parse(localStorage.getItem('jwt')).roles[0] !== 'ROLE_MODERATOR' && <>
            <input name="ficha[id]" type="hidden" value={pk} ref={register()} />
            <div className="mb-3 row">
              <div className="col">
                <label className="form-label fw-bold">{t('label-anioFicha')}</label>
                <span className="input-container">
                  <i><FontAwesomeIcon icon="user-friends" /></i>
                  <select name="anio" className="form-select" ref={register({ required: 'required-field' })}>
                    <option value="">{t('placeholder-anio')}</option>
                    {
                      anios.map(item => {
                        return <option value={item} key={item}>{item}</option>
                      })
                    }
                  </select>
                </span>
                <ErrorMessage errors={errors}
                  name="anio"
                  render={({ message }) =>
                    <small className="error">
                      {t(message)}
                    </small>}
                />
              </div>
              <div className="col">
                <label className="form-label fw-bold">{t('label-fuentes')}</label>
                <span className="input-container">
                  <i><FontAwesomeIcon icon="sort-numeric-up" /></i>
                  <input type="text" name="fuente" className="form-control" ref={register({ required: 'required-field' })} />
                </span>
                <ErrorMessage errors={errors}
                  name="fuente"
                  render={({ message }) =>
                    <small className="error">
                      {t(message)}
                    </small>}
                />
              </div>
            </div>
            <div className="mb-3 row">
              <div className="col">
                <label className="form-label fw-bold">{t('label-cantidadMujeres')}</label>
                <span className="input-container">
                  <i><FontAwesomeIcon icon="female" /></i>
                  <input type="number" name="cantidadMujeres" className="form-control" ref={register({ required: 'required-field' })} min="0" />
                </span>
                <ErrorMessage errors={errors}
                  name="cantidadMujeres"
                  render={({ message }) =>
                    <small className="error">
                      {t(message)}
                    </small>}
                />
              </div>
              <div className="col">
                <label className="form-label fw-bold">{t('label-cantidadHombres')}</label>
                <span className="input-container">
                  <i><FontAwesomeIcon icon="male" /></i>
                  <input type="number" name="cantidadHombres" className="form-control" ref={register({ required: 'required-field' })} min="0" />
                </span>
                <ErrorMessage errors={errors}
                  name="candidadHombres"
                  render={({ message }) =>
                    <small className="error">
                      {t(message)}
                    </small>}
                />
              </div>
            </div> </>
        }
        <div className="row">
          <div className="col">
            {
              JSON.parse(localStorage.getItem('jwt')) && JSON.parse(localStorage.getItem('jwt')).roles[0] !== 'ROLE_MODERATOR' &&
              <ButtonSubmit formState={formState} loading={loading} label={t('button-save')} />
            }
            {
              lista && lista.length === 4 &&
              <Link className="btn btn-secondary btn-lg" to='/firstPage'>{t('button-goToMenu')}</Link>
            }
          </div>
        </div>
      </form>
      <Lista t={t} lista={lista} ficha={pk} />
    </div>
  )
}

function Lista({ t, lista, ficha }) {
  const [arreglo, setArreglo] = useState([])

  function deleteItem(e) {
    Swal.fire({
      title: t('message-confirm-delete'),
      text: t('message-advice-delete'),
      icon: 'warning',
      showDenyButton: true,
      confirmButtonColor: '#3085d6',
      denyButtonColor: '#d33',
      confirmButtonText: t('message-yes'),
      denyButtonText: t('message-no'),
    }).then((result) => {
      if (result.isConfirmed) {
        axiosService.remove(`${urlSubmit}/${e}`)
          .then(response => {
            if (response.status === 204) {
              setArreglo(arreglo.filter(element => element.id !== e))
              MyAlert({ title: t('message-deleteSuccessful'), icon: 'success' })
            }
          }).catch(error => {
            if (error.message === 'Request failed with status code 500')
              MyAlert({ title: t('message-noDelete'), icon: 'error' })
          })
      }
    })
  }

  useEffect(() => {
    setArreglo(lista)
  }, [lista])

  return (
    <div>
      <div className="row">
        <div className="col">
          <hr className="my-4" />
          {
            arreglo.length > 0 &&
            <Table striped responsive hover>
              <thead>
                <tr>
                  <th>{t('label-anioFicha')}</th>
                  <th>{t('label-fuentes')}</th>
                  <th colSpan="3" style={{ textAlign: 'center' }}>{t('label-ops')}</th>
                </tr>
              </thead>
              <tbody>
                {
                  arreglo.map(item => {
                    return <tr key={item.id}>
                      <td>{item.anio}</td>
                      <td>{item.fuente}</td>
                      {
                        JSON.parse(localStorage.getItem('jwt')) && JSON.parse(localStorage.getItem('jwt')).roles[0] !== 'ROLE_MODERATOR' && <>
                          <td>
                            <Link to={`/ficha/${ficha}/fichaNacimientos/edit/${item.id}`}>
                              <FontAwesomeIcon icon={'edit'} />
                            </Link>
                          </td>
                          <td>
                            <button onClick={() => deleteItem(item.id)}>
                              <FontAwesomeIcon icon={'trash-alt'} />
                            </button>
                          </td></>
                      }
                      <td>
                        <Link to={`/ficha/${ficha}/fichaNacimientos/show/${item.id}`}>
                          <FontAwesomeIcon icon={'glasses'} />
                        </Link>
                      </td>
                    </tr>
                  })
                }
              </tbody>
            </Table>
          }
          {
            arreglo.length === 0 && <div>{t('message-notRecord')}</div>
          }
        </div>
      </div>
    </div>
  )
}

export default withNamespaces()(NewFichaNacimientos)