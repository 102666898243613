import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ErrorMessage } from "@hookform/error-message"
import { useEffect, useState } from "react"
import { Button, Modal, Table } from "react-bootstrap"
import { useForm } from "react-hook-form"
import { withNamespaces } from "react-i18next"
import Swal from "sweetalert2"
import { Link, useLocation } from "wouter"
import MyAlert from "../../common/MyAlert"
import useIndicadoresRonda from "../../hooks/useIndicadoresRonda"
import { axiosService } from "../../services/axiosService"
import ButtonSubmit from "../Common/ButtonSubmit"
import { BodyContent, UserTitle } from "../Users/styles"
const url = `/api/v1/fichaIdentificacion`

const New = ({ t }) => {
  const { handleSubmit, formState, register, errors, reset } = useForm({
    mode: "all"
  })
  const { connError, authError } = useIndicadoresRonda()
  const [loading, setLoading] = useState(false)
  const [, setLocation] = useLocation()
  let pais = null
  if (JSON.parse(localStorage.getItem('jwt'))) {
    pais = JSON.parse(localStorage.getItem('jwt')).pais.id
  }
  const [lista, setLista] = useState([])
  const [show, setShow] = useState(true)
  const handleClose = () => setShow(false)

  const onSubmit = (data, e) => {
    e.preventDefault()
    setLoading(true)
    axiosService.create(url, data)
      .then(response => {
        if (response.status === 201) {
          MyAlert({ title: t('message-successful'), icon: 'success' })
          reset()
        }
      }).catch(error => {
        if (error.message === 'Request failed with status code 401') {
          MyAlert({ title: t('message-userUnauthorized'), icon: 'error' })
        } else if (error.message === 'Request failed with status code 500') {
          MyAlert({ title: t('message-noRecordCountry'), icon: 'error' })
        } else if (error.message === 'Network Error') {
          MyAlert({ title: t('message-connError'), icon: 'error' })
        }
      }).finally(() => {
        setTimeout(() => {
          setLoading(false)
        }, 3000)
      })
  }

  useEffect(() => {
    if (connError) {
      setLocation("/noconn")
      MyAlert({ title: t('message-connError'), icon: 'error' })
    } else if (authError || !JSON.parse(localStorage.getItem('jwt'))) {
      setLocation("/")
      MyAlert({ title: t('message-userUnauthorized'), icon: 'error' })
    }
  }, [authError, connError, setLocation, t, reset])

  useEffect(() => {
    axiosService.getAll(`${url}/pais/${pais}`)
      .then(response => setLista(response.data))
      .catch(error => { })
  }, [pais, loading])

  const newLine = (line) => {
    return line.split('\n').map(str => <BodyContent key={str}>{str}</BodyContent>)
  }

  return (
    <div>
      <div className="row">
        <div className="col">
          <div>
            <UserTitle>{t('title-fichaIdentificacion')}</UserTitle>
            <hr />
          </div>
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="needs-validation">
        {
          JSON.parse(localStorage.getItem('jwt')) && JSON.parse(localStorage.getItem('jwt')).roles[0] !== 'ROLE_MODERATOR' && <>
            <input name="pais[id]" type="hidden" value={pais} ref={register()} />
            <div className="mb-3 row">
              <div className="col">
                <label className="form-label fw-bold">{t('label-nombrePais')}</label>
                <div>{JSON.parse(localStorage.getItem('jwt')) ? JSON.parse(localStorage.getItem('jwt')).pais.descripcion : null}</div>
              </div>
              <div className="col">
                <label className="form-label fw-bold">{t('label-institucionInfo')}</label>
                <span className="input-container">
                  <i><FontAwesomeIcon icon="signature" /></i>
                  <input type="text" name="institucion" className="form-control" ref={register({ required: 'required-field' })} />
                </span>
                <ErrorMessage errors={errors}
                  name="institucion"
                  render={({ message }) =>
                    <small className="error">
                      {t(message)}
                    </small>}
                />
              </div>
            </div>
            <div className="mb-3 row">
              <div className="col">
                <label className="form-label fw-bold">{t('label-autoridad')}</label>
                <span className="input-container">
                  <i><FontAwesomeIcon icon="signature" /></i>
                  <input type="text" name="autoridad" className="form-control" ref={register({ required: 'required-field' })} />
                </span>
                <ErrorMessage errors={errors}
                  name="autoridad"
                  render={({ message }) =>
                    <small className="error">
                      {t(message)}
                    </small>}
                />
              </div>
              <div className="col">
                <label className="form-label fw-bold">{t('label-oficina')}</label>
                <span className="input-container">
                  <i><FontAwesomeIcon icon="signature" /></i>
                  <input type="text" name="oficina" className="form-control" ref={register({ required: 'required-field' })} />
                </span>
                <ErrorMessage errors={errors}
                  name="oficina"
                  render={({ message }) =>
                    <small className="error">
                      {t(message)}
                    </small>}
                />
              </div>
            </div></>
        }
        <div className="row">
          <div className="col">
            {
              JSON.parse(localStorage.getItem('jwt')) && JSON.parse(localStorage.getItem('jwt')).roles[0] !== 'ROLE_MODERATOR' &&
              <ButtonSubmit formState={formState} loading={loading} label={t('button-save')} />
            }
            {
              lista && lista.length === 1 &&
              <Link className="btn btn-secondary btn-lg" to={`/ficha/${lista[0].id}/demograficos`}>{t('button-siguiente')}</Link>
            }
          </div>
        </div>
      </form>
      <Lista t={t} lista={lista} />
      {
        <Modal show={show} onHide={handleClose} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>
              <h3 style={{ textAlign: 'center' }}>
                {t('title-modal')}
              </h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              {newLine(t('body-modal'))}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              {t('button-comenzar')}
            </Button>
          </Modal.Footer>
        </Modal>
      }
    </div>
  )
}

function Lista({ t, lista }) {
  const [arreglo, setArreglo] = useState([])

  function deleteItem(e) {
    Swal.fire({
      title: t('message-confirmDelete'),
      text: t('message-adviceDelete'),
      icon: 'warning',
      showDenyButton: true,
      confirmButtonColor: '#3085d6',
      denyButtonColor: '#d33',
      confirmButtonText: t('message-yes'),
      denyButtonText: t('message-no'),
    }).then((result) => {
      if (result.isConfirmed) {
        axiosService.remove(`${url}/${e}`)
          .then(response => {
            if (response.status === 204) {
              setArreglo(arreglo.filter(element => element.id !== e))
              MyAlert({ title: t('message-deleteSuccessful'), icon: 'success' })
            }
          }).catch(error => {
            if (error.message === 'Request failed with status code 500')
              MyAlert({ title: t('message-noDelete'), icon: 'error' })
          })
      }
    })
  }

  useEffect(() => {
    setArreglo(lista)
  }, [lista])

  return (
    <div>
      <div className="row">
        <div className="col">
          <hr className="my-4" />
          {
            arreglo.length > 0 &&
            <Table striped responsive hover>
              <thead>
                <tr>
                  <th>{t('label-nombrePais')}</th>
                  <th>{t('label-institucionInfo')}</th>
                  <th>{t('label-autoridad')}</th>
                  <th colSpan="6" style={{ textAlign: 'center' }}>{t('label-ops')}</th>
                </tr>
              </thead>
              <tbody>
                {
                  arreglo.map(item => {
                    return <tr key={item.id}>
                      <td>{`${item.pais['descripcion']}`}</td>
                      <td>{item.institucion}</td>
                      <td>{item.autoridad}</td>
                      {
                        JSON.parse(localStorage.getItem('jwt')) && JSON.parse(localStorage.getItem('jwt')).roles[0] !== 'ROLE_MODERATOR' && <>
                          <td>
                            <Link to={`/fichaIdentificacion/edit/${item.id}`}>
                              <FontAwesomeIcon icon={'edit'} />
                            </Link>
                          </td>
                          <td>
                            <button onClick={() => deleteItem(item.id)}>
                              <FontAwesomeIcon icon={'trash-alt'} />
                            </button>
                          </td></>
                      }
                      <td>
                        <Link to={`/fichaIdentificacion/show/${item.id}`}>
                          <FontAwesomeIcon icon={'glasses'} />
                        </Link>
                      </td>
                      <td>
                        <Link to={`/ficha/${item.id}/defunciones`}>
                          {t('label-datosDefuncion')}
                        </Link>
                      </td>
                      <td>
                        <Link to={`/ficha/${item.id}/demograficos`}>
                          {t('label-datosDemograficos')}
                        </Link>
                      </td>
                      <td>
                        <Link to={`/ficha/${item.id}/fichaNacimientos`}>
                          {t('title-fichaNacimientos')}
                        </Link>
                      </td>
                    </tr>
                  })
                }
              </tbody>
            </Table>
          }
          {
            arreglo.length === 0 && <div>{t('message-notRecord')}</div>
          }
        </div>
      </div>
    </div>
  )
}

export default withNamespaces()(New)