import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ErrorMessage } from "@hookform/error-message"
import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { withNamespaces } from "react-i18next"
import { Link, useLocation, useRoute } from "wouter"
import MyAlert from "../../common/MyAlert"
import { axiosService } from "../../services/axiosService"
import ButtonSubmit from "../Common/ButtonSubmit"
import { generateYearsBetween } from "../Common/generateBetweenYears"
import { UserTitle } from "../Users/styles"
const urlSubmit = `/api/v1/datosDefunciones`

const EditDefunciones = ({ t }) => {
  const [match, params] = useRoute("/ficha/:ficha/defunciones/edit/:pk")
  const { ficha, pk } = params
  const { handleSubmit, formState, register, errors, reset } = useForm({
    mode: "all"
  })
  const [loading, setLoading] = useState(false)
  const [authError, setAuthError] = useState(false)
  const [, setLocation] = useLocation()
  const anios = generateYearsBetween(2018)

  const onSubmit = (data, e) => {
    e.preventDefault()
    setLoading(true)
    axiosService.update(`${urlSubmit}/${pk}`, data)
      .then(response => {
        if (response.status === 200) {
          MyAlert({ title: t('message-successful'), icon: 'success' })
          reset()
        }
      }).catch(error => {
        if (error.message === 'Request failed with status code 401') {
          setAuthError(true)
        } else if (error.message === 'Request failed with status code 500') {
          MyAlert({ title: t('message-noRecordAnio'), icon: 'error' })
        }
      }).finally(() => {
        setTimeout(() => {
          setLoading(false)
        }, 3000)
      })
  }

  useEffect(() => {
    if (authError || !JSON.parse(localStorage.getItem('jwt'))) {
      setLocation("/")
      MyAlert({ title: t('message-userUnauthorized'), icon: 'error' })
    }
  }, [t, setLocation, authError, reset])

  useEffect(() => {
    axiosService.getAll(`${urlSubmit}/${pk}`)
      .then(response => reset(response.data))
      .catch(error => { })
  }, [pk, reset])

  return (
    <div>
      <div className="row">
        <div className="col">
          <UserTitle>{t('label-datosDefuncion')}</UserTitle>
          <hr />
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="needs-validation">
        <input name="ficha[id]" type="hidden" value={ficha} ref={register()} />
        <div className="mb-3 row">
          <div className="col">
            <label className="form-label fw-bold">{t('label-anioFicha')}</label>
            <span className="input-container">
              <i><FontAwesomeIcon icon="user-friends" /></i>
              <select name="anio" className="form-select" ref={register({ required: 'required-field' })}>
                <option value="">{t('placeholder-anio')}</option>
                {
                  anios.map(item => {
                    return <option value={item} key={item}>{item}</option>
                  })
                }
              </select>
            </span>
            <ErrorMessage errors={errors}
              name="anio"
              render={({ message }) =>
                <small className="error">
                  {t(message)}
                </small>}
            />
          </div>
          <div className="col">
            <label className="form-label fw-bold">{t('label-fuentes')}</label>
            <span className="input-container">
              <i><FontAwesomeIcon icon="sort-numeric-up" /></i>
              <input type="text" name="fuente" className="form-control" ref={register({ required: 'required-field' })} />
            </span>
            <ErrorMessage errors={errors}
              name="fuente"
              render={({ message }) =>
                <small className="error">
                  {t(message)}
                </small>}
            />
          </div>
        </div>
        <div className="mb-3 row">
          <div className="col-md-6">
            <label className="form-label fw-bold">{t('label-defuncionMujeres')}</label>
          </div>
        </div>
        <div className="mb-3 row">
          <div className="col">
            <label className="form-label fw-bold">{t('label-edad0')}</label>
            <span className="input-container">
              <i><FontAwesomeIcon icon="female" /></i>
              <input type="number" name="cantidadMujeres0" className="form-control" ref={register({ required: 'required-field' })} min="0" />
            </span>
            <ErrorMessage errors={errors}
              name="cantidadMujeres0"
              render={({ message }) =>
                <small className="error">
                  {t(message)}
                </small>}
            />
          </div>
          <div className="col">
            <label className="form-label fw-bold">{t('label-edad20')}</label>
            <span className="input-container">
              <i><FontAwesomeIcon icon="female" /></i>
              <input type="number" name="cantidadMujeres20" className="form-control" ref={register({ required: 'required-field' })} min="0" />
            </span>
            <ErrorMessage errors={errors}
              name="cantidadMujeres20"
              render={({ message }) =>
                <small className="error">
                  {t(message)}
                </small>}
            />
          </div>
        </div>
        <div className="mb-3 row">
          <div className="col">
            <label className="form-label fw-bold">{t('label-edad35')}</label>
            <span className="input-container">
              <i><FontAwesomeIcon icon="female" /></i>
              <input type="number" name="cantidadMujeres35" className="form-control" ref={register({ required: 'required-field' })} min="0" />
            </span>
            <ErrorMessage errors={errors}
              name="cantidadMujeres35"
              render={({ message }) =>
                <small className="error">
                  {t(message)}
                </small>}
            />
          </div>
          <div className="col">
            <label className="form-label fw-bold">{t('label-edad50')}</label>
            <span className="input-container">
              <i><FontAwesomeIcon icon="female" /></i>
              <input type="number" name="cantidadMujeres50" className="form-control" ref={register({ required: 'required-field' })} min="0" />
            </span>
            <ErrorMessage errors={errors}
              name="cantidadMujeres50"
              render={({ message }) =>
                <small className="error">
                  {t(message)}
                </small>}
            />
          </div>
        </div>
        <div className="mb-3 row">
          <div className="col-md-6">
            <label className="form-label fw-bold">{t('label-edad65')}</label>
            <span className="input-container">
              <i><FontAwesomeIcon icon="female" /></i>
              <input type="number" name="cantidadMujeres65" className="form-control" ref={register({ required: 'required-field' })} min="0" />
            </span>
            <ErrorMessage errors={errors}
              name="cantidadMujeres65"
              render={({ message }) =>
                <small className="error">
                  {t(message)}
                </small>}
            />
          </div>
        </div>
        <div className="mb-3 row">
          <div className="col-md-6">
            <label className="form-label fw-bold">{t('label-defuncionHombres')}</label>
          </div>
        </div>
        <div className="mb-3 row">
          <div className="col">
            <label className="form-label fw-bold">{t('label-edad0')}</label>
            <span className="input-container">
              <i><FontAwesomeIcon icon="male" /></i>
              <input type="number" name="cantidadHombres0" className="form-control" ref={register({ required: 'required-field' })} min="0" />
            </span>
            <ErrorMessage errors={errors}
              name="cantidadHombres0"
              render={({ message }) =>
                <small className="error">
                  {t(message)}
                </small>}
            />
          </div>
          <div className="col">
            <label className="form-label fw-bold">{t('label-edad20')}</label>
            <span className="input-container">
              <i><FontAwesomeIcon icon="male" /></i>
              <input type="number" name="cantidadHombres20" className="form-control" ref={register({ required: 'required-field' })} min="0" />
            </span>
            <ErrorMessage errors={errors}
              name="cantidadHombres20"
              render={({ message }) =>
                <small className="error">
                  {t(message)}
                </small>}
            />
          </div>
        </div>
        <div className="mb-3 row">
          <div className="col">
            <label className="form-label fw-bold">{t('label-edad35')}</label>
            <span className="input-container">
              <i><FontAwesomeIcon icon="male" /></i>
              <input type="number" name="cantidadHombres35" className="form-control" ref={register({ required: 'required-field' })} min="0" />
            </span>
            <ErrorMessage errors={errors}
              name="cantidadHombres35"
              render={({ message }) =>
                <small className="error">
                  {t(message)}
                </small>}
            />
          </div>
          <div className="col">
            <label className="form-label fw-bold">{t('label-edad50')}</label>
            <span className="input-container">
              <i><FontAwesomeIcon icon="male" /></i>
              <input type="number" name="cantidadHombres50" className="form-control" ref={register({ required: 'required-field' })} min="0" />
            </span>
            <ErrorMessage errors={errors}
              name="cantidadHombres50"
              render={({ message }) =>
                <small className="error">
                  {t(message)}
                </small>}
            />
          </div>
        </div>
        <div className="mb-3 row">
          <div className="col-md-6">
            <label className="form-label fw-bold">{t('label-edad65')}</label>
            <span className="input-container">
              <i><FontAwesomeIcon icon="male" /></i>
              <input type="number" name="cantidadHombres65" className="form-control" ref={register({ required: 'required-field' })} min="0" />
            </span>
            <ErrorMessage errors={errors}
              name="cantidadHombres65"
              render={({ message }) =>
                <small className="error">
                  {t(message)}
                </small>}
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <ButtonSubmit formState={formState} loading={loading} label={t('button-update')} />
            <Link className="btn btn-secondary btn-lg" to={`/ficha/${ficha}/defunciones`}>{t('button-back')}</Link>
          </div>
        </div>
      </form>
    </div>
  )
}

export default withNamespaces()(EditDefunciones)