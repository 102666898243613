import React, { forwardRef } from 'react'
import { ErrorMessage } from '@hookform/error-message'
import '../styles/Nav.css'
import { Trans } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const SelectField = forwardRef((props, ref) => (
  <div className={props.col}>
    <label className={ref ? 'font-weight-bold' : 'font-weight-normal'}>{props.label}</label>
    <span className="input-container">
      <i><FontAwesomeIcon icon={['fas', props.icon ? props.icon : 'coffee']} /></i>
      <select name={props.name} ref={ref} className="custom-select"
        style={{ width: props.size ? props.size : '100%' }} onChange={props.change}>
        <option value="">{props.placeholder}</option>
        {
          props.data && props.data.map((e, key) => {
            return <option key={key} value={e.id}>{e.descripcion}</option>
          })
        }
      </select>
    </span>
    {
      props.errors &&
      <ErrorMessage errors={props.errors} name={props.name} render={({ message }) => <p className="error"><small><Trans>{message}</Trans></small></p>} />
    }
  </div>
))

export default SelectField
