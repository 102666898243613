import { useEffect, useState } from "react"
import { Link, useRoute } from "wouter"
import useLocation from "wouter/use-location"
import { axiosService } from "../../services/axiosService"
import { UserTitle } from "../Users/styles"
const url = `/api/v1/tablaDetalle`

function List() {
  const [match, params] = useRoute("/tabla/:nombre")
  const { nombre } = params || {}
  const [lista, setLista] = useState([])
  const [, setLocation] = useLocation()

  useEffect(() => {
    axiosService.getAll(`${url}/${nombre}`)
      .then(response => {
        setLista(response.data)
      }).catch(error => {
        setLocation("/")
      })
  }, [nombre, setLocation])

  return (
    <div>
      <div className="row">
        <div className="col">
          <br /><Link className="btn btn-secondary btn-lg ml-2" to={`/tabla/${nombre}/new`}>Nuevo</Link>
          <UserTitle>Labels</UserTitle><hr />
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Nombre Campo BD</th>
                  <th>Nombre Campo Back</th>
                  <th>Tipo Campo</th>
                  <th>URL</th>
                  <th>Orden</th>
                  <th>¿Listar?</th>
                </tr>
              </thead>
              <tbody>
                {
                  lista.map(item => {
                    return <Link to={`/tabla/${item.id}/edit`}>
                      <tr>
                        <td>{item.nombreCampo}</td>
                        <td>{item.nameField}</td>
                        <td>{item.type}</td>
                        <td>{item.url}</td>
                        <td>{item.fieldOrder}</td>
                        <td>{item.list ? 'SI' : 'NO'}</td>
                      </tr>
                    </Link>
                  })
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default List