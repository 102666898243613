const axios = require('axios')

export const axiosWrapper = {
  get,
  post,
  put,
  remove,
  getWA
}

async function get(url) {
  try {
    return await axios({
      method: 'get',
      url: url,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token'))}`
      }
    })
  } catch (error) {
    if (error.message === 'Request failed with status code 401')
      localStorage.clear()
    throw new Error(error.message)
  }
}

async function post(url, data) {
  try {
    return await axios({
      method: 'post',
      url: url,
      data: data,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token'))}`
      }
    })
  } catch (error) {
    if (error.message === 'Request failed with status code 401')
      localStorage.clear()
    throw new Error(error.message)
  }
}

async function put(url, data) {
  try {
    return await axios({
      method: 'put',
      url: url,
      data: data,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token'))}`
      }
    })
  } catch (error) {
    if (error === 'Error: Request failed with status code 401')
      localStorage.clear()
    throw new Error(error.message)
  }
}

async function remove(url) {
  try {
    return await axios({
      method: 'delete',
      url: url,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token'))}`
      }
    })
  } catch (error) {
    if (error.message === 'Request failed with status code 401')
      localStorage.clear()
    throw new Error(error.message)
  }
}

async function getWA(url) {
  try {
    return await axios({
      method: 'get',
      url: url,
      headers: {
        'Content-Disposition': "attachment; filename=output.xlsx",
        'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      },
      responseType: 'arraybuffer',
    })
  } catch (error) {
    if (error.message === 'Request failed with status code 401')
      localStorage.clear()
    throw new Error(error.message)
  }
}