export default function HeadTable({ values }) {
  return (
    <thead>
      <tr>
        {
          values.map(item => {
            return <th scope="col" className={`col-${item.size}`} key={item.label}>{item.label}</th>
          })
        }
      </tr>
    </thead>
  )
}