import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import { axiosService } from "../../services/axiosService"
import { UserTitle } from "../Users/styles"
import { withNamespaces } from 'react-i18next'
import { Link } from "wouter"

function Modulos({ t }) {
  const [modulos, setModulos] = useState([])
  const [paises, setPaises] = useState([])
  const [pais, setPais] = useState(JSON.parse(localStorage.getItem('jwt')) && JSON.parse(localStorage.getItem('jwt')).roles[0] === 'ROLE_MODERATOR' ? JSON.parse(localStorage.getItem('idPais')) : null)

  useEffect(() => {
    axiosService.getAll(`/api/v1/modulos`)
      .then(result => setModulos(result.data))
  }, [])

  useEffect(() => {
    axiosService.getAll(`/api/v1/paises`)
      .then(result => setPaises(result.data))
  }, [])

  return (
    <div>
      <UserTitle>{t('title-reporteIndicadores')}</UserTitle>
      <hr />
      {
        JSON.parse(localStorage.getItem('jwt')) && JSON.parse(localStorage.getItem('jwt')).roles[0] === 'ROLE_ADMIN' &&
        <div className="form-group col-md-6" style={{ marginBottom: '1rem' }}>
          <label className="form-label fw-bold">
            {t('label-country')}
          </label>
          <span className="input-container">
            <i><FontAwesomeIcon icon="globe" /></i>
            <select className="form-select" onChange={(e) => setPais(e.target.value)}>
              <option value="">{t('placeholder-pais')}</option>
              {
                paises && paises.length > 0 &&
                paises.map((e, key) => {
                  return <option key={key} value={e.id}>{e.descripcion}</option>
                })
              }
            </select>
          </span>
        </div>
      }

      {
        pais && pais !== null && modulos && modulos.length > 0 && <h5>{t('label-clicModulo')}</h5>
      }

      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {
          pais && pais !== null && modulos && modulos.length > 0 && modulos.map((item, key) => {
            return <div style={{ width: '30%', marginBottom: '1rem', textAlign: 'center' }} key={key}>
              <div>
                <Link to={`/reporte/${item.id}/pais/${pais}`} style={{ textDecoration: 'none' }}>
                  <FontAwesomeIcon icon="file-excel" size="6x" /><p>{item.descripcion}</p>
                </Link>
              </div>
            </div>
          })

        }
      </div>
    </div>
  )
}

export default withNamespaces()(Modulos)