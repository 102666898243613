import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect } from "react"
import { useForm } from "react-hook-form"
import { withNamespaces } from "react-i18next"
import { Link, useLocation, useRoute } from "wouter"
import MyAlert from "../../common/MyAlert"
import { axiosService } from "../../services/axiosService"
import { generateYearsBetween } from "../Common/generateBetweenYears"
import { UserTitle } from "../Users/styles"
const urlSubmit = `/api/v1/datosDemograficos`

const ShowDemograficos = ({ t }) => {
  const [match, params] = useRoute("/ficha/:ficha/demograficos/show/:pk")
  const { pk, ficha } = params
  const { register, reset } = useForm({
    mode: "all"
  })
  const authError = false
  const [, setLocation] = useLocation()
  const anios = generateYearsBetween(2018)

  useEffect(() => {
    if (authError || !JSON.parse(localStorage.getItem('jwt'))) {
      setLocation("/")
      MyAlert({ title: t('message-userUnauthorized'), icon: 'error' })
    }
  }, [t, setLocation, authError, reset])

  useEffect(() => {
    axiosService.getAll(`${urlSubmit}/${pk}`)
      .then(response => reset(response.data))
      .catch(error => { })
  }, [pk, reset])

  return (
    <div>
      <div className="row">
        <div className="col">
          <UserTitle>{t('label-datosDemograficos')}</UserTitle>
          <hr />
        </div>
      </div>

      <input name="ficha[id]" type="hidden" value={ficha} ref={register()} />
      <div className="mb-3 row">
        <div className="col">
          <label className="form-label fw-bold">{t('label-anioFicha')}</label>
          <span className="input-container">
            <i><FontAwesomeIcon icon="user-friends" /></i>
            <select disabled='true' name="anio" className="form-select" ref={register({ required: 'required-field' })}>
              <option value="">{t('placeholder-anio')}</option>
              {
                anios.map(item => {
                  return <option value={item} key={item}>{item}</option>
                })
              }
            </select>
          </span>

        </div>
        <div className="col">
          <label className="form-label fw-bold">{t('label-fuentes')}</label>
          <span className="input-container">
            <i><FontAwesomeIcon icon="sort-numeric-up" /></i>
            <input disabled='true' type="text" name="fuente" className="form-control" ref={register({ required: 'required-field' })} min="0" />
          </span>

        </div>
      </div>
      <div className="mb-3 row">
        <div className="col-md-6">
          <label className="form-label fw-bold">{t('label-poblacionMujeres')}</label>
        </div>
      </div>
      <div className="mb-3 row">
        <div className="col">
          <label className="form-label fw-bold">{t('label-edad0')}</label>
          <span className="input-container">
            <i><FontAwesomeIcon icon="female" /></i>
            <input disabled='true' type="number" name="cantidadMujeres0" className="form-control" ref={register({ required: 'required-field' })} min="0" />
          </span>

        </div>
        <div className="col">
          <label className="form-label fw-bold">{t('label-edad20')}</label>
          <span className="input-container">
            <i><FontAwesomeIcon icon="female" /></i>
            <input disabled='true' type="number" name="cantidadMujeres20" className="form-control" ref={register({ required: 'required-field' })} min="0" />
          </span>

        </div>
      </div>
      <div className="mb-3 row">
        <div className="col">
          <label className="form-label fw-bold">{t('label-edad35')}</label>
          <span className="input-container">
            <i><FontAwesomeIcon icon="female" /></i>
            <input disabled='true' type="number" name="cantidadMujeres35" className="form-control" ref={register({ required: 'required-field' })} min="0" />
          </span>

        </div>
        <div className="col">
          <label className="form-label fw-bold">{t('label-edad50')}</label>
          <span className="input-container">
            <i><FontAwesomeIcon icon="female" /></i>
            <input disabled='true' type="number" name="cantidadMujeres50" className="form-control" ref={register({ required: 'required-field' })} min="0" />
          </span>

        </div>
      </div>
      <div className="mb-3 row">
        <div className="col-md-6">
          <label className="form-label fw-bold">{t('label-edad65')}</label>
          <span className="input-container">
            <i><FontAwesomeIcon icon="female" /></i>
            <input disabled='true' type="number" name="cantidadMujeres65" className="form-control" ref={register({ required: 'required-field' })} min="0" />
          </span>

        </div>
      </div>
      <div className="mb-3 row">
        <div className="col-md-6">
          <label className="form-label fw-bold">{t('label-poblacionHombres')}</label>
        </div>
      </div>
      <div className="mb-3 row">
        <div className="col">
          <label className="form-label fw-bold">{t('label-edad0')}</label>
          <span className="input-container">
            <i><FontAwesomeIcon icon="male" /></i>
            <input disabled='true' type="number" name="cantidadHombres0" className="form-control" ref={register({ required: 'required-field' })} min="0" />
          </span>

        </div>
        <div className="col">
          <label className="form-label fw-bold">{t('label-edad20')}</label>
          <span className="input-container">
            <i><FontAwesomeIcon icon="male" /></i>
            <input disabled='true' type="number" name="cantidadHombres20" className="form-control" ref={register({ required: 'required-field' })} min="0" />
          </span>

        </div>
      </div>
      <div className="mb-3 row">
        <div className="col">
          <label className="form-label fw-bold">{t('label-edad35')}</label>
          <span className="input-container">
            <i><FontAwesomeIcon icon="male" /></i>
            <input disabled='true' type="number" name="cantidadHombres35" className="form-control" ref={register({ required: 'required-field' })} min="0" />
          </span>

        </div>
        <div className="col">
          <label className="form-label fw-bold">{t('label-edad50')}</label>
          <span className="input-container">
            <i><FontAwesomeIcon icon="male" /></i>
            <input disabled='true' type="number" name="cantidadHombres50" className="form-control" ref={register({ required: 'required-field' })} min="0" />
          </span>

        </div>
      </div>
      <div className="mb-3 row">
        <div className="col-md-6">
          <label className="form-label fw-bold">{t('label-edad65')}</label>
          <span className="input-container">
            <i><FontAwesomeIcon icon="male" /></i>
            <input disabled='true' type="number" name="cantidadHombres65" className="form-control" ref={register({ required: 'required-field' })} min="0" />
          </span>

        </div>
      </div>
      <div className="row">
        <div className="col">
          <Link className="btn btn-secondary btn-lg" to={`/ficha/${ficha}/demograficos`}>{t('button-back')}</Link>
        </div>
      </div>
    </div>
  )
}

export default withNamespaces()(ShowDemograficos)